import React, { useEffect, useState } from 'react'
import authentication from "@kdpw/msal-b2c-react";
//img
import CarValooLogo from "../../assets/images/carvaloo-logo.png";
//#import DashboardLogo from "../../assets/images/dashboard-new.png";
//import EventsLogo from "../../assets/images/events-new.png";
//import VehicleLogo from "../../assets/images/vehicles-new.png";
import DashboardLogo from "../../assets/images/monitoring.png";
import EventsLogo from "../../assets/images/car_crash.png";
import VehicleLogo from "../../assets/images/cars.png";
import EinflottungLogo from "../../assets/images/car_station.png";
import StatisticLogo from "../../assets/images/fleet_analytics.png";

//hook
import { useLanguageContext } from "../../language-service/container/Language";
//util
import { roleRenderer } from '../../utility/role-renderer';
//component
import { NavLink, Link } from 'react-router-dom'
import { Popover } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import UserMenu from "../UserMeu/UserMenu";
import SideBarItem from './SideBarItem/SideBarItem';
import { getEventCount } from '../../services/events-service';
import { useSelector } from 'react-redux';
import { isNavLinkShow } from '../../utility/screen-renderer';

const SideBar = () => {
  const { dictionary } = useLanguageContext();
  const [eventBadgeData, setEventBadgeData] = useState(0);
  const screenPermissions2 = useSelector(state => state?.OrganizationConfigReducer?.screenPermissions2)

  useEffect(() => {
    getEventCount()
      .then((res) => setEventBadgeData(res.data))
  }, [])

  return (
    <div className="shadow side-bar-container">
      <img
        className="mt-3"
        src={CarValooLogo}
        style={{ width: "50px" }}
        alt=""
      />

      <div className="side-bar-menu">
        {isNavLinkShow('DASHBOARD', screenPermissions2) && <SideBarItem to='/dashboard' imgSrc={DashboardLogo} content={dictionary.mainLayout__overview_text} />}
        {isNavLinkShow('EVENTS', screenPermissions2) && <SideBarItem to='/ereignisse' eventBadgeData={eventBadgeData} imgSrc={EventsLogo} content={dictionary.mainLayout__events_text} />}
        {isNavLinkShow('VEHICLES', screenPermissions2) && <SideBarItem to='/fahrzeug' imgSrc={VehicleLogo} content={dictionary.mainLayout__vehicles_text} />}
        {isNavLinkShow('FLEET', screenPermissions2) && <SideBarItem to='/fleet' imgSrc={EinflottungLogo} content={dictionary.mainLayout__fleet_text} />}
        {isNavLinkShow('STATISTICS', screenPermissions2) && <SideBarItem to='/statistic' imgSrc={StatisticLogo} content={dictionary.mainLayout__stats_text} />}
        {/* {roleRenderer(["admin"], roleName) && <SideBarItem to='/monitoring' imgSrc={MonitoringLogo} content={dictionary.mainLayout__monitoring_text} />} */}
      </div>

      <div className="d-flex flex-column align-items-center mb-3">
        <Link to="/search">
          <SearchOutlined style={{ fontSize: "25px", marginBottom: "10px", color: "white" }}/>
        </Link>
        <Popover
          overlayClassName="popover-auto-width"
          trigger="click"
          placement="topLeft"
          content={<UserMenu logout={authentication.signOut} />}
          title="Organization"
        >
          <i className="far fa-power-off side-bar-popup-icon"/>
        </Popover>
      </div>
    </div>
  )
}

export default SideBar
