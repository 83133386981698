const isNavLinkShow = (type, screenPermissions2) => !!screenPermissions2[type]

const isBasicElShow = (type, screenPermissions2) => !!screenPermissions2[type]?.basic_elements && !!Object.keys(screenPermissions2[type].basic_elements).length 

const locationMapper = (pathname) => pathname === 'fahrzeug' ? 'VEHICLES' : pathname === 'dashboard' ? 'DASHBOARD' : 'EVENTS';

export {
  isNavLinkShow,
  isBasicElShow,
  locationMapper,
}