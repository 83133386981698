import { Spin } from 'antd';
import React from 'react'

const MainLoader = () => {
  return (
    <div className='main-loader-container'>
      <img
        src={require("../../assets/images/carValoo-splash-logo.png")}
        className='main-loader-logo'
      />
      <Spin size="large" />
    </div>
  )
}

export default MainLoader
