import React, { useEffect } from "react";
import { Button } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { useHistory } from "react-router-dom";
import IconSeverity from "../../Shared/IconSeverity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerSlow,
  faHome,
  faRouter,
} from "@fortawesome/pro-light-svg-icons";
import { convertSeverityToColor } from "../../../utility/severity-color-converter";
const ye = new Intl.DateTimeFormat("en", { year: "numeric" });
const mo = new Intl.DateTimeFormat("de", { month: "short" });
const da = new Intl.DateTimeFormat("en", { day: "2-digit" });

const InfoCardVehicle = (props) => {
  const history = useHistory();
  return (
    <div className="p-3 d-flex flex-column" style={{ height: "100%" }}>
      {props.info && props.info.vehicleId && (
        <div className="d-flex align-items-center ml-1 justify-content-between">
          <h6 className="mb-0 pl-2">
            Fahrzeug {props.info.vehicleId}
            <br />
            <span
              style={{
                color: "#c6c4c4",
                fontSize: "12px",
              }}
            >
              {props.info && props.info.vehicleType}
            </span>
          </h6>
          <IconSeverity
            inSideColor={convertSeverityToColor(props.info.severity)}
            outSideColor={convertSeverityToColor(props.info.severity)}
            height={45}
            width={45}
          />
        </div>
      )}

      <div className="d-flex align-items-center mt-2 ml-2">
        <FontAwesomeIcon icon={faHome} style={{ fontSize: "1.1rem" }} />
        <p className="mb-0 ml-2">{props.info && props.info.stationName}</p>
      </div>
      <div className="d-flex align-items-center mt-2 ml-2">
        <FontAwesomeIcon
          icon={faTachometerSlow}
          style={{ fontSize: "1.1rem" }}
        />
        <p className="mb-0 ml-2">10.999 km </p>
      </div>

      {props.info && props.info.boxId && (
        <div className="d-flex align-items-end mt-2 ml-2">
          <FontAwesomeIcon icon={faRouter} style={{ fontSize: "1.1rem" }} />
          <p className="mb-0 ml-2">{props.info && props.info.boxId}</p>
        </div>
      )}

      <Button
        onClick={() => history.push(`/fahrzeug/${props.info.vehicleId}`)}
        className="mt-3"
        icon={<SearchOutlined />}
        type="primary"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "auto",
        }}
      >
        <span style={{ marginTop: "1px" }}>Details</span>
      </Button>
    </div>
  );
};

export default InfoCardVehicle;
