import { axiosInstance } from "./axiosInstance";
import authentication from "@kdpw/msal-b2c-react";
import {axiosInstanceLocal} from "./axiosInstance-local"

export const getUserRoles = () => {
  return axiosInstance.get("/api/get-auth", {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
      // SkipOrganizationName: "true",
    },
  });
};

// export const getUserRoles = () => {
//   return axiosInstance.get("/api/UserManagement/me", {
//     headers: {
//       Authorization: `Bearer ${authentication.getAccessToken()}`,
//       SkipOrganizationName: "true",
//     },
//   });
// };

export const getUserOrganizations = () => {
  return axiosInstance.get("/api/UserManagement/organizations", {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getUserProperties = () => {
  return axiosInstance.get("/api/UserManagement/properties", {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getOrganizationScreenPermissions2 = () => {
  return axiosInstance.get("/api/team-screenscontent2", {
  //return axiosInstanceLocal.get("/api/GetScreensContent_2", {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};
