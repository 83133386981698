import React, {lazy, Suspense} from "react";
// hook
import { useLocation } from "react-router-dom";
//component
import { Switch, Route, Redirect } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import SideBar from "../components/SideBar/SideBar";
import ProtectedRoute from "../hoc/ProtectedRoute";
import { useSelector } from "react-redux";
import { isBasicElShow, locationMapper } from "../utility/screen-renderer";
import MainLoader from "../components/MainLoader/MainLoader";
//pages
const EventsScreen = lazy(() => import("../screens/EventsScreen"));
const VehicleScreen = lazy(() => import("../screens/VehicleScreen"));
const MonitoringScreen = lazy(() => import("../screens/MonitoringScreen"));
const HomeScreen = lazy(() => import("../screens/HomeScreen"));
const FleetScreen = lazy(() => import("../screens/FleetScreen"));
const StatsScreen = lazy(() => import("../screens/StatsScreen"));
const ErrorPage = lazy(() => import("../screens/ErrorPage"));

const MainLayout = () => {
  const location = useLocation();
  const screenPermissions2 = useSelector(state => state?.OrganizationConfigReducer?.screenPermissions2);

  return (
    <Suspense fallback={<MainLoader />}>
      <div className="d-flex">
        <SideBar/>
        <div className={`main-wrapper ${location.pathname !== "/dashboard" ? '' : 'dashboard'} ${isBasicElShow(locationMapper(location.pathname.split('/')[1]), screenPermissions2) ? '' : 'no-basic-display'}`}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/null" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route
              path="/dashboard"
              exact={true}
              component={HomeScreen}
            ></Route>
            <Route path="/ereignisse" component={EventsScreen}></Route>
            <Route path="/fahrzeug" component={VehicleScreen}></Route>
            <Route path="/fleet" component={FleetScreen}></Route>
            <Route path="/statistic" component={StatsScreen}></Route>
            <ProtectedRoute
              permittedRoles={["carvaloo_administrator"]}
              path="/monitoring"
              component={MonitoringScreen}
            />
            <Route path="/error" component={ErrorPage}></Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
};

export default MainLayout;
