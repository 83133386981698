import React from 'react'
import ReactMarkdown from 'react-markdown'
import './markdown.scss'

function Markdown({children}) {
  return (
    <ReactMarkdown className="markdown" linkTarget='_blank'>
      {children}
    </ReactMarkdown>
  )
}

export default Markdown
