import axios from "axios";
import {axiosInstance} from "./axiosInstance";
import {axiosInstanceLocal} from "./axiosInstance-local"
import authentication from "@kdpw/msal-b2c-react";
import mockRes from '../mock/eventUpdateData.json'

export let eventVehiclePartsCancelToken;
export let eventMapLocationCancelToken;
export let eventInformationCancelToken;
export let eventMediaCancelToken;
export let eventHistoryCancelToken;
export let eventHeadingCancelToken;
export let eventDocsCancelToken;
export let eventNoticesCancelToken;
export let eventFormDataCancelToken;
export let eventMapHistoryCancelToken;
export let eventPaginationCancelToken;


export const getEventMapHistory = (reportUrl) => {
    return axiosInstance.get('/api/Events/GetMapJson', {
        params: {
            url: reportUrl
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
            'Content-Type': 'application/json'
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventMapHistoryCancelToken = c;
        }),
    });
};
export const getEventsCommonType = (pageSize, pageIndex, filter) => {
    return axiosInstance.post(
        "api/Events/GetEventData",
        {
            pageSize,
            pageIndex,
            filter,
        },
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
            cancelToken: new axios.CancelToken(function (c) {
                eventPaginationCancelToken = c;
            }),
        }
    );
};

export const getEventsGenericType = (pageSize, pageIndex, filter) => {
    const params = {
        pageSize,
        pageIndex,
        filter,
    }
    return axiosInstance.get(
        "api/event/pagination", {params}
        ,
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
            cancelToken: new axios.CancelToken(function (c) {
                eventPaginationCancelToken = c;
            }),
        }
    );
};
// TODO : Refactoring

function serialize(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const getEventsGenericTypeWithPagination = (pageSize, pageIndex, filter = {}) => {
    let filterQueryString = "";
    if (Object.keys(filter).length) {
        filterQueryString  = "&" + serialize(filter);
    }
    return axiosInstance.get(
        `api/event/pagination?page_size=${pageSize}&page_index=${pageIndex}${filterQueryString}`,
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            }
        }
    );
};


export const getEventDetailCommonType = (eventId) => {
    return axiosInstance.get(`api/Events/GetEventDetail?eventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
    });
};
export const getEventDetailGenericType = (eventId) => {
    return axiosInstance.get(`/api/event/mapcard`, {
        params: {
            event_id: eventId
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
    });
};

export const getEventVehiclePartsCommonType = (eventId) => {
    return axiosInstance.get(`api/Events/GetEventStatus?EventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventVehiclePartsCancelToken = c;
        }),
    });
};

export const getEventVehiclePartsByEventIdGenericType = (eventId) => {
    return axiosInstance.get(`/api/damage`, {
        params: {
            event_id: eventId
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        }
    });
};

export const getEventScreenTitle = (eventId) => {
    return axiosInstance.get(`/api/event/title`, {
        params: {
            event_id: eventId,
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        }
    });
};

export const getEventMapLocationCommonType = (eventId) => {
    return axiosInstance.get(`api/Events/GetMap?EventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventMapLocationCancelToken = c;
        }),
    });
};
export const getEventMapLocationGenericType = (eventId) => {
    return axiosInstance.get(`/api/event/mapposition`, {
        params: {
            event_id: eventId,
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        }
    });
};

export const getEventInformation = (eventId) => {
    return axiosInstance.get(`/api/event/information`, {
        params: {
            event_id: eventId,
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventInformationCancelToken = c;
        }),
    });
};

export const getEventMediaCommonType = (eventId) => {
    return axiosInstance.get(`api/Events/GetMedia?EventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventMediaCancelToken = c;
        }),
    });
};

export const getEventMediaGenericType = (eventId, UVID) => {
    // It is one of the eventId or vehicleId
    return axiosInstance.get(`/api/event/documents`, {
        params: {
            event_id: eventId,
            UVID: UVID,
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        }
    });
};

export const getEventHistory = (eventId) => {
    return axiosInstance.get(
        `api/Events/GetEventHistoryInfo/?eventId=${eventId}`,
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
            cancelToken: new axios.CancelToken(function (c) {
                eventHistoryCancelToken = c;
            }),
        }
    );
};

export const getEventHistoryGenericType = (eventId) => {
    return axiosInstance.get(
        `/api/event/history`,
        {
            params: {
                event_id: eventId,
            },
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            }
        }
    );
};

export const getEventNotesGenericType = (eventId) => {
    return axiosInstance.get(
        `/api/event/notes`,
        {
            params: {
                event_id: eventId
            },
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            }
        }
    );
};

export const getEventHeading = (eventId) => {
    return axiosInstance.get(
        `api/Events/GetEventHeadingInfo/?eventId=${eventId}`,
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
            cancelToken: new axios.CancelToken(function (c) {
                eventHeadingCancelToken = c;
            }),
        }
    );
};

export const getEventSeverityList = () => {
    return axiosInstance.get(`api/Events/GetSeverityList`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
    });
};

export const getEventDocs = (eventId) => {
    return axiosInstance.get(`api/Events/GetEventDoc?eventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventDocsCancelToken = c;
        }),
    });
};

export const getEventNotices = (eventId) => {
    return axiosInstance.get(`api/Events/GetEventNote?eventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventNoticesCancelToken = c;
        }),
    });
};

export const getProcessTypes = () => {
    return axiosInstance.get(`api/Events/GetProrcessType`, {
        headers: {Authorization: `Bearer ${authentication.getAccessToken()}`},
    });
};

export const addEventHistory = (eventId, info, processType) => {
    return axiosInstance.post(`/api/Events/AddEventHistory`, {
        eventId,
        info,
        processType,
    });
};

export const updateEventLog = (eventId, body) => {
    return axiosInstance.post(
        `/api/Events/UpdateLog?eventId=${eventId}`,
        {...body},
        {
            headers: {Authorization: `Bearer ${authentication.getAccessToken()}`},
        }
    );
};
export const updateEventNotes = (eventId, body) => {
    return axiosInstance.post(
        `/api/Events/UpdateNotes?eventID=${eventId}`,
        {
            ...body,
        },
        {
            headers: {Authorization: `Bearer ${authentication.getAccessToken()}`},
        }
    );
};

export const getEventFormData = (eventId) => {
    return axiosInstance.get(`/api/Vehicles/GetEvent?eventId=${eventId}`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventFormDataCancelToken = c;
        }),
    });
};
export const getCreateEventFormData = (eventId) => {
    const mockData = {'title': 'Ereignisverlauf',
    'authentication': 'Webapp Service User',
    'data': [{'component': 'select',
      'dataKey': 'process_type',
      'value': '',
      'payload': [{'displayValue': 'Rücksprache mit Kunde',
        'value': 'customer_feedback'},
       {'displayValue': 'Rechnungsstellung an Kunde', 'value': 'invoice'},
       {'displayValue': 'Schadenreparatur erfolgt', 'value': 'repair'},
       {'displayValue': 'Vorfall abgeschlossen', 'value': 'abgeschlossen'}],
      'placeholder': 'Bearbeitungstyp auswählen',
      'uid': 'MAddEventHistorySelect1'},
     {'component': 'textarea',
      'value': '',
      'dataKey': 'info_text',
      'placeholder': '',
      'uid': 'MAddEventHistoryTA1'}]}

    // return new Promise((resolve,reject) => {resolve({data:mockData})})

    return axiosInstance.get(`/api/modaladdeventhistory`, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        cancelToken: new axios.CancelToken(function (c) {
            eventFormDataCancelToken = c;
        }),
    });
};

export const getEventVerticleTimelineConditionalData = (params, endpointString) => {
    return axiosInstance.get(endpointString, {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        params
    })
}

export const getEventFormDataGenericType = ({eventId,vehicleId,isUpdate, UVID}) => {
    let url;
    if(isUpdate) {
        url = `/api/event/update-modal`
        //url = `api/KachelModalEventUpdate`
    } else {
        url = `/api/event/new-modal`
        //url = `api/KachelModalEventNew`
    }
    return axiosInstance.get(url, {
    //return axiosInstanceLocal.get(url, {
        params: {
            event_id: eventId,
            vehicle_id: vehicleId,
            isUpdate: isUpdate,
            UVID: UVID
        },
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        }
    });
};

export const editEvent = (eventId, eventData) => {
    return axiosInstance.put( `/api/Vehicles/UpdateEvent${eventId ? '?event_id='+eventId : ''}`,
    //return axiosInstanceLocal.post( `/api/UpdateEvent${eventId ? '?event_id='+eventId : ''}`,
        {
            ...eventData,
        },
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
        }
    );


};
export const getFilterComponents = (isVehicleScreen) => {
    return axiosInstance.get(
        `/api/event/filter-components?${isVehicleScreen ? 'screen=vehicle' : ""}`,
        {
            headers: {
                Authorization: `Bearer ${authentication.getAccessToken()}`,
            },
        }
    )
}

export const getEventStatusGenericType = (event_id) => {
  return axiosInstance.get('/api/eventstatus',
    {
      params: {
        event_id
      },
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
    }
  )
}

export const getEventCount = () => {
    return axiosInstance.get('/api/getopenalerts',
      {
        headers: {
          Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
      }
    )
}

export const getEventFeedback = (eventId) => {
    return axiosInstance.get(`/api/kacheleventfeedback?event_id=${eventId}`,
      {
        headers: {
          Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
      }
    )
  }

export const getModalExport = () => {
  return axiosInstance.get('/api/modalexport', {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  })
}

export const getExportData = (params) => {
    return axiosInstance.get('/api/exportdata', {
        headers: {
            Authorization: `Bearer ${authentication.getAccessToken()}`,
        },
        params
    })
}


export const getExportReportPDFData = (params) => {
  //return axiosInstanceLocal.get('/api/ModalCreatePDFReport/', {
  return axiosInstance.get('/api/createpdfreport', {
    params: params,
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  })
}

export const getReportPDFData = (params) => {
    return axiosInstance.post('/api/getpdfreport',
    //return axiosInstanceLocal.post('/api/CreatePDFReport/',
        {...params},
        {
            headers: {Authorization: `Bearer ${authentication.getAccessToken()}`},
        }
    );
}

export const getCarDamageHistory = (params) => {
  //return axiosInstanceLocal.get('/api/ModalAddDamageHistory', {
  return axiosInstance.get('/api/ModalAddDamageHistory', {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    params
  })
}

    
      
export const updateCarDamageHistory = (endpoint, params) => {
    endpoint = endpoint.split('api/')[1]
    return axiosInstance.post(
        `/api/${endpoint}`,
        {...params},
        {
            headers: {Authorization: `Bearer ${authentication.getAccessToken()}`},
        }
    );
};