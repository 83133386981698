import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// REDUCERS
import UserReducer from "./user-reducer";
import VehiclesReducer from "./vehicle-reducer";
import MonitoringsReducer from "./monitoring-reducer";
import OrganizationConfigReducer from "./organization-config-reducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    UserReducer,
    VehiclesReducer,
    MonitoringsReducer,
    OrganizationConfigReducer,
  });

export default createRootReducer;
