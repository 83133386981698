import axios from "axios";
import { axiosInstance } from "./axiosInstance";
import {axiosInstanceLocal} from "./axiosInstance-local";
import authentication from "@kdpw/msal-b2c-react";
export let vehiclePartsCancelToken;
export let vehicleTimelineDataCancelToken;
export let vehiclePositionsCancelToken;
export let vehicleMediaCancelToken;
export let vehicleHistoryCancelToken;
export let vehicleDetailCancelToken;
export let vehicleDocsCancelToken;
export let vehicleNoticesCancelToken;
export let vehiclePositionInfoCancelToken;
export let vehicleReservationDataCancelToken;
export let vehiclePaginationCancelToken;

export const getVehicles = (pageSize, pageIndex, filter) => {
  return axiosInstance.post(
    "api/Vehicles/GetVehicleData",
    {
      filter,
    },
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehiclePaginationCancelToken = c;
      }),
    }
  );
};

export const getVehicleParts = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetVehicleStatus?VehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehiclePartsCancelToken = c;
      }),
    }
  );
};

export const getEventVehiclePartsGenericType = (vehicleId) => {
  return axiosInstance.get(`/api/damage`, {
    params: {
      vehicle_id: vehicleId,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getReservationData = (vehicleId, startDate, endDate) => {
  const api = `api/Vehicles/GetVehicleReservations?VehicleId=${vehicleId}&StartDate=${startDate}&EndDate=${endDate}`;
  return axiosInstance.get(api, {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    cancelToken: new axios.CancelToken(function (c) {
      vehicleReservationDataCancelToken = c;
    }),
  });
};

export const getPositionInfo = (vehicleId) => {
  const api = `api/Vehicles/PositionLast`;
  return axiosInstance.get(api, {
    params: {
      vehicle_id: vehicleId,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    cancelToken: new axios.CancelToken(function (c) {
      vehiclePositionInfoCancelToken = c;
    }),
  });
};

export const getVehicleTimelineData = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetTimelineData?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehicleTimelineDataCancelToken = c;
      }),
    }
  );
};

export const getVehicleMedia = (vehicleId) => {
  return axiosInstance.get(`api/Vehicles/GetMedia/?vehicleId=${vehicleId}`, {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    cancelToken: new axios.CancelToken(function (c) {
      vehicleMediaCancelToken = c;
    }),
  });
};

export const getVehicleHistory = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetVehicleHistoryInfo/?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehicleHistoryCancelToken = c;
      }),
    }
  );
};

export const getVehicleDetails = (vehicleId) => {
  return axiosInstance.get(`api/Vehicles/GetVehicleDetail/?vehicleId=${vehicleId}`,
  //return axiosInstanceLocal.get(`api/KachelVehicleDetails/?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehicleDetailCancelToken = c;
      }),
    }
  );
};

export const getVehicleHeading = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetVehicleHeadingInfo/?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
    }
  );
};

export const getVehicletypes = () => {
  return axiosInstance.get(`api/Vehicles/GetVehicleTypes`, {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleDocs = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetVehicleDoc?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehicleDocsCancelToken = c;
      }),
    }
  );
};

export const getVehicleStats = (vehicleId) => {
  return axiosInstance.get(`api/Vehicles/GetVehicleStats`, {
    params: {
      vehicle_id: vehicleId,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    cancelToken: new axios.CancelToken(function (c) {
      vehicleDocsCancelToken = c;
    }),
  });
};

export const getVehicleNotices = (vehicleId) => {
  return axiosInstance.get(
    `api/Vehicles/GetVehicleNote?vehicleId=${vehicleId}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        vehicleNoticesCancelToken = c;
      }),
    }
  );
};

export const addEventToVehicle = (form) => {
  return axiosInstance.post(
    `api/Vehicles/AddEvent`,
    { ...form },
    { headers: { Authorization: `Bearer ${authentication.getAccessToken()}` } }
  );
};

export const deleteMedia = (data) => {
  return axiosInstance.put(
    `api/Vehicles/DeleteMedia`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
    }
  );
};

export const saveMedia = (data) => {
  return axiosInstance.post(
    `api/Vehicles/AddMedia`,
    { ...data },
    { headers: { Authorization: `Bearer ${authentication.getAccessToken()}` } }
  );
};
// TODO : Refactoring

function serialize(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const getVehiclesGenericTypeWithPagination = (
  pageSize,
  pageIndex,
  filter = {},
  UVID
) => {
  let filterQueryString = "";
  if (Object.keys(filter).length) {
    filterQueryString = "&" + serialize(filter);
  }
  return axiosInstance.get(
    `api/vehicles/pagination?page_size=${pageSize}&page_index=${pageIndex}${filterQueryString}`,
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      params: {
        UVID: UVID,
      },
    }
  );
};

export const getVehicleMediaGenericType = (vehicleId, UVID) => {
  // It is one of the eventId or vehicleId
  return axiosInstance.get(`/api/vehicle/documents`, {
    params: {
      vehicle_id: vehicleId,
      UVID: UVID,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleDetailsGenericType = (vehicleId) => {
  return axiosInstance.get(`/api/vehicle/details`, {
    params: {
      vehicle_id: vehicleId,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleHistoryGenericType = (vehicleId) => {
  return axiosInstance.get(`/api/vehicle/history`, {
    params: {
      vehicle_id: vehicleId,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleEventGenericType = (vehicleId, UVID) => {
  return axiosInstance.get(`/api/vehicle/events`, {
    params: {
      vehicle_id: vehicleId,
      UVID: UVID,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleDetailGenericType = (vehicleId) => {
  return axiosInstance.get(`/api/vehiclemapcard?vehicle_id=${vehicleId}`, {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};

export const getVehicleScreenTitle = (vehicleId, UVID) => {
  return axiosInstance.get(`api/vehicletitle`, {
    params: {
      vehicle_id: vehicleId,
      UVID: UVID,
    },
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
  });
};
