import React from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import useLocalStorageDetect from "../../custom-hooks/useLocalStorageDetect";
// Styles
import styles from "./UserMenu.module.scss";
import { useHistory } from "react-router";

function UserMenu({ logout }) {
  const { roleData: user } = useSelector((state) => state.UserReducer);
  const organizationName = useLocalStorageDetect("organizationName");

  return (
    <div
      className={`d-flex flex-column mx-n3 mt-n3 pt-2 ${styles.menuContainer}`}
    >
      <p className="py-2 d-flex align-items-center pl-3 mb-0">
        <i className="fal fa-building"></i>
        <span className="ml-2 pr-3 text-truncate">{organizationName}</span>
      </p>
      <p className="py-2 d-flex align-items-center pl-3 mb-0">
        <i className="fal fa-user"></i>
        <span className="ml-2 pr-3 text-truncate">{user.email}</span>
      </p>
      <Popover
        overlayClassName="popover-auto-width"
        trigger="click"
        placement="right"
        content={
          <UserOrganizationsList
            activeOrganization={organizationName}
            allOrganizations={user.organizations}
          />
        }
        title="User Organizations"
      >
        <p
          className={`py-2 d-flex align-items-center pl-3 mb-0 text-light ${styles.buttonExchange}`}
        >
          <i className="fal fa-exchange"></i>
          <span className="ml-2 pr-3 text-truncate">Change Organization</span>
        </p>
      </Popover>
      <p
        className={`py-2 d-flex align-items-center pl-3 mb-0 text-light ${styles.buttonLogout}`}
        onClick={() => logout()}
      >
        <i className="fal fa-sign-out-alt"></i>
        <span className="ml-2 pr-3 text-truncate">Logout</span>
      </p>
    </div>
  );
}

const UserOrganizationsList = ({ activeOrganization, allOrganizations }) => {
  const history = useHistory()

  const handleChangeOrganization = (organization) => {
    localStorage.setItem("organizationName", organization);
    history.push('/dashboard')
    window.location.reload();
  };
  return (
    <div
      className={`d-flex flex-column mx-n3 mt-n3 pt-2 ${styles.organizations}`}
    >
      {allOrganizations.map((organization, i) => (
        <p
          className="py-2 d-flex align-items-center pl-3 mb-0 text-capitalize"
          key={i}
          onClick={() => handleChangeOrganization(organization)}
        >
          {activeOrganization.toLowerCase() === organization.toLowerCase() && (
            <i className="fal fa-check"></i>
          )}
          <span className="ml-2 pr-3 text-truncate">{organization}</span>
        </p>
      ))}
    </div>
  );
};

export default UserMenu;
