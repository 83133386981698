import React from "react";
import DocumentScreen from "../../screens/DocumentScreen/DocumentScreen";

const Item = ({ cursor, text, margin, children }) => {
  return (
    <>
      <span className={`${margin ? margin : "mr-3"}`}>{cursor}</span>&nbsp;
      {text}
      {children}
    </>
  );
};
function Datenschutzerklärung() {
  return (
    <>
      <DocumentScreen>
        <div className="row">
          <div className="col-12">
            <h2 className="display-3 text-center mb-2">Datenschutzerklärung</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h4 className="text-center">carValoo WebApp, carValoo GmbH</h4>
            <h5 className="text-center text-muted mt-3">Stand 19.11.2020</h5>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <p>
              Die Inhalte der carValoo WebApp (kurz: App) werden von der
              carValoo GmbH bereitgestellt. Die carValoo GmbH ist ein
              Konzernunternehmen der thyssenkrupp AG in dem Segment Automotive
              Technology. Mit Hilfe der carValoo WebApp erhalten die Kunden der
              carValoo GmbH eine interaktive Schnittstelle, mit der sie alle
              Informationen der carValoo Services abrufen und verwalten können.
              Dazu gehören insbesondere Informationen über den Status bzw. den
              Zustand der Fahrzeugflotte des Kunden sowie Detailinformationen zu
              Schadenereignissen von Fahrzeugen. Der Umgang des Anwenders mit
              personenbezogenen Daten entspricht den strengen Anforderungen der
              DSGVO.
            </p>
            <p>
              Der Schutz Ihrer Privatsphäre bei der Nutzung unserer App ist uns
              ein besonderes Anliegen. Aus diesem Grund möchten wir Sie im
              Folgenden über die Erhebung und Verarbeitung personenbezogener
              Daten informieren.
            </p>
          </div>
          <div className="col-12 mt-3">
            <h6 className="font-weight-bold mb-3">
              <Item cursor="1" text="Allgemeine Informationen" margin="mr-2" />
            </h6>
          </div>
          <div className="col-12">
            <p className="font-weight-bold">
              <Item
                cursor="1.1"
                text="Wer ist für die Verarbeitung personenbezogener Daten
              verantwortlich und an wen können Sie sich wenden?"
                margin="mr-2"
              />
            </p>
            <p>
              Verantwortlicher für die Verarbeitung personenbezogener Daten ist:
            </p>
            <p className="font-weight-bold">carValoo GmbH</p>
            <p>
              ThyssenKrupp Allee 1<br />
              45143 Essen
              <br />
              Deutschland
            </p>
            <p>E-Mail: datenschutz@carValoo.com</p>
            <p>Unser Datenschutzbeauftragter ist erreichbar unter:</p>
            <p className="font-weight-bold">Winfried Peters</p>
            <p>
              Information Security Officer thyssenkrupp Automotive Technology
              (AT/ITM) thyssenkrupp AG | Automotive Technology
            </p>
            <p>
              E-Mail:&nbsp;
              <a href="mailto:winfried.peters@thyssenkrupp.com">
                winfried.peters@thyssenkrupp.com
              </a>
            </p>
            <p>
              Tel:&nbsp;
              <a href="tel:+49 201 844 537866">+49 201 844 537866</a>
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item cursor="1.2" text="Was wir verarbeiten" margin="mr-2" />
            </p>
            <p>
              Wir verarbeiten die von Ihnen zur Verfügung gestellten
              personenbezogenen Daten für das Registrierungs- und
              Identitätsmanagement.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="1.3"
                text="Zu welchem Zweck und auf welcher Rechtsgrundlage verarbeiten
              wir personenbezogene Daten?"
                margin="mr-2"
              />
            </p>
            <p>
              Wir verarbeiten Ihre personenbezogenen Daten in Übereinstimmung
              mit den Bestimmungen der Europäischen Allgemeinen
              Datenschutzverordnung (GDPR) und dem geltenden nationalen Recht
              für verschiedene Zwecke. Weitere Informationen finden Sie in
              Abschnitt II. - "Verarbeitung personenbezogener Daten.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="1.4"
                text="Wer erhält Ihre personenbezogenen Daten?"
                margin="mr-2"
              />
            </p>
            <p>
              Dienstleister (Verarbeiter, siehe Art. 4 Nr. 8 GDPR) können in
              unserem Auftrag personenbezogene Daten erheben und verarbeiten.
              Wir verwenden die folgenden Kategorien von Verarbeitern:
            </p>
            <p className="pl-3">
              <Item cursor="●" text="IT-Dienstleister" margin="mr-2" />
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="1.5"
                text="Wie lange verarbeiten wir personenbezogene Daten?"
                margin="mr-2"
              />
            </p>
            <p>
              Wir verarbeiten Daten nur so lange, wie es für den ursprünglich
              angegebenen, ausdrücklichen und legitimen Zweck erforderlich ist."
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="1.6"
                text="Sind Sie verpflichtet, personenbezogene Daten anzugeben?"
                margin="mr-2"
              />
            </p>
            <p>
              Es besteht keine gesetzliche oder vertragliche Verpflichtung, uns
              personenbezogene Daten zur Verfügung zu stellen. Wir bitten Sie
              nur, uns die für unsere Dienstleistungen notwendigen Daten zur
              Verfügung zu stellen. Ohne diese personenbezogenen Daten können
              wir Ihnen unsere Dienstleistungen nicht anbieten.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="1.7"
                text="Links zu Websites oder Anwendungen Dritter"
                margin="mr-2"
              />
            </p>
            <p>
              Die App kann Links zu Websites oder Apps von Drittanbietern
              enthalten, einschließlich Social Media Websites/Apps. Jeglicher
              Zugriff auf und jede Nutzung von solchen verlinkten
              Websites/Anwendungen unterliegt nicht dieser Richtlinie. Diese
              Websites/Anwendungen von Drittanbietern unterliegen den
              Datenschutzrichtlinien dieser Websites/Anwendungen von
              Drittanbietern. Wir sind nicht verantwortlich für die Verarbeitung
              personenbezogener Daten auf solchen Websites/Apps Dritter.
            </p>
          </div>
          <div className="col-12">
            <p className="font-weight-bold">
              <Item cursor="1.8" text="Ihre Rechte" margin="mr-2" />
            </p>
            <p>
              Sie haben das Recht auf Zugang (Art. 15 GDPR), das Recht auf
              Berichtigung (Art. 16 GDPR), das Recht auf Löschung (Art. 17
              GDPR), das Recht auf Einschränkung der Verarbeitung (Art. 18 GDPR)
              und das Recht auf Datenübertragbarkeit (Art. 20 GDPR).
            </p>
            <p>
              Wenn personenbezogene Daten aufgrund Ihrer Einwilligung
              verarbeitet werden, haben Sie das Recht, Ihre Einwilligung gemäß
              Art. 7 (3) GDPR zu widerrufen. Bitte beachten Sie, dass Ihr
              Widerruf nur die zukünftige Verarbeitung mit Ihrer Zustimmung
              betrifft.
            </p>
            <p>
              Um von diesen Rechten Gebrauch zu machen, können Sie sich unter
              den in Abschnitt I. 1. angegebenen Kontaktdaten an uns wenden.
            </p>
            <p>
              Soweit die personenbezogenen Daten zum Zwecke unseres berechtigten
              Interesses gemäß Art. 6 Abs. 1 lit. f GDPR haben Sie das Recht,
              nach Art. 21 GDPR zu widersprechen. Weitere Informationen zu Ihrem
              Widerspruchsrecht finden Sie am Ende dieser Datenschutzerklärung.
            </p>
            <p>
              Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer
              personenbezogenen Daten rechtswidrig ist, haben Sie darüber hinaus
              das Recht, bei der zuständigen Aufsichtsbehörde eine Beschwerde
              einzureichen (Art. 77 GDPR). Dieses Beschwerderecht lässt andere
              administrative oder gerichtliche Rechtsbehelfe unberührt.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item cursor="2" text="Verarbeitung personenbezogener Daten" />
            </p>
            <p className="font-weight-bold">
              <Item
                cursor="2.1"
                text="Automatisierte Erfassung von Zugangsdaten/Server-Logfiles"
              />
            </p>
            <p>
              Bei der Nutzung unserer App werden bei jedem Abruf die folgenden
              Zugangsdaten in Server-Logfiles gespeichert:
            </p>
            <p>Name</p>
            <p>Nachname</p>
            <p>Firmenname</p>
            <p>Firmenanschrift</p>
            <p>Postleitzahl des Unternehmens</p>
            <p>Firmenkunden-ID</p>
            <p>Land der Gesellschaft</p>
            <p>E-Mail-Adresse</p>
            <p>
              Die Verarbeitung personenbezogener Daten basiert auf Art. 6 (1)
              lit. f GDPR. Zweck und unser legitimes Interesse sind die
              Verwaltung und Verbesserung der Funktionalität unserer App sowie
              die Vermeidung von Sicherheitsproblemen.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item cursor="2.2" text="Cookies" />
            </p>
            <p>
              Um Ihren Besuch in unseren Apps attraktiv zu gestalten und die
              Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
              Cookies. Cookies sind Textdateien, die auf Ihrem Computer oder
              einem anderen Endgerät gespeichert werden. Der Einsatz von Cookies
              hilft uns, Ihre Nutzung zu analysieren, die angebotenen
              Dienstleistungen und Produkte zu verbessern, die Sicherheit zu
              verbessern und Betrug zu verhindern. Wir unterscheiden zwischen
              verschiedenen Cookies:
            </p>
          </div>
          <div className="col-12 pl-4">
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Session-Cookies sind temporäre Cookie-Dateien, die automatisch gelöscht werden, wenn Sie Ihren Browser schließen."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Persistente Cookies sind Cookie-Dateien, die gespeichert werden, bis sie aktiv gelöscht oder nach einer bestimmten Zeit automatisch wieder entfernt werden."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="First-Party-Cookies sind Cookies, die direkt von uns gesetzt werden."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Drittanbieter-Cookies sind von einem Drittanbieter gesetzte Cookies."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Drittanbieter-Cookies sind von einem Drittanbieter gesetzte Cookies."
              />
            </p>
          </div>
          <div className="col-12">
            <p>Cookies können für verschiedene Zwecke eingesetzt werden:</p>
          </div>
          <div className="col-12 pl-4">
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Funktionale Cookies werden verwendet, um Ihnen die Standarddienste auf unseren Seiten/Applikationen zur Verfügung zu stellen. Ohne die Verwendung von Cookies können unsere Websites und Apps nur sehr eingeschränkt und möglicherweise fehlerhaft funktionieren."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="●"
                text="Optimierungs-Cookies ermöglichen es uns, die Nutzung unserer Website und Apps zu analysieren. Die Analyse hilft uns, unsere Dienstleistungen zu verbessern und die angebotenen Produkte und Dienstleistungen zu verbessern."
              />
            </p>
          </div>
          <div className="col-12 pl-5">
            <p>
              <Item
                margin="mr-2"
                cursor="-"
                text="Personalisierungs-Cookies ermöglichen es uns, Ihre persönlichen Präferenzen, wie z.B. Ihre bevorzugte Sprache, zu ermitteln. Wir verwenden diese Cookies, um die Benutzerfreundlichkeit zu verbessern und Ihnen ein individuelles Erlebnis zu bieten."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="-"
                text="Sicherheitsrelevante Cookies sind Cookies, die notwendig sind, um die Website/Anwendungen vor Angriffen zu schützen und Betrug zu verhindern."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="-"
                text="Social Media Cookies werden verwendet, um dem Besucher der Website und/oder dem App-Benutzer Social Media-Funktionen anzubieten."
              />
            </p>
            <p>
              <Item
                margin="mr-2"
                cursor="-"
                text="Werbe-Cookies werden verwendet, um dem Besucher der Website und/oder dem App-Nutzer personalisierte Werbung zu liefern. '"
              />
            </p>
          </div>
          <div className="col-12">
            <p>
              Für unsere App verwenden wir nur Cookies, die für den Betrieb der
              App unbedingt erforderlich sind. Diese Nutzung ist nicht möglich,
              wenn Sie die Nutzung nicht erlaubt haben.
            </p>
          </div>
          <div className="col-12">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Cookie</th>
                  <th scope="col">Benutzung</th>
                  <th scope="col">Verarbeitete Information</th>
                  <th scope="col">Dauer und Speicherplatz</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>msal.state.login</td>
                  <td>
                    Security-relevant cookies Domain: webapp. carvaloo.com
                  </td>
                  <td>Securing connection</td>
                  <td>Session Cookie Expires after Browser- Session</td>
                </tr>
                <tr>
                  <td>msal.state.acquireToken</td>
                  <td>
                    Security-relevant cookies Domain: webapp. carvaloo.com
                  </td>
                  <td>Securing connection</td>
                  <td>Session Cookie Expires after Browser- Session</td>
                </tr>
                <tr>
                  <td>msal.login.request</td>
                  <td>
                    Security-relevant cookies Domain: webapp. carvaloo.com
                  </td>
                  <td>Securing connection</td>
                  <td>Session Cookie Expires after Browser- Session</td>
                </tr>
                <tr>
                  <td>msal.nonce.idtoken</td>
                  <td>
                    Security-relevant cookies Domain: webapp. carvaloo.com
                  </td>
                  <td>Securing connection</td>
                  <td>Session Cookie Expires after Browser- Session</td>
                </tr>
                <tr>
                  <td>ARRAffinity</td>
                  <td>SessionId für Azure Webapp</td>
                  <td>Application Request Routing on scaled environments</td>
                  <td>Session Cookie Expires after User- Session</td>
                </tr>
                <tr>
                  <td>ARRAffinitySameSite</td>
                  <td>SessionId für Azure Webapp</td>
                  <td>Application Request Routing on scaled environments</td>
                  <td>Session Cookie Expires after User- Session</td>
                </tr>
              </tbody>
            </table>
            <p>
              Die Verarbeitung personenbezogener Daten durch den Einsatz von
              funktionalen First-Party-Cookies basiert auf Art. 6 (1) lit. f
              GDPR.
            </p>
            <p>
              Wenn die Anonymisierung von IP-Adressen auf einer Website aktiv
              ist, wird die IP-Adresse des Benutzers innerhalb der Europäischen
              Union oder des Europäischen Wirtschaftsraums gekürzt. Nur in
              Ausnahmefällen wird die vollständige IP-Adresse des Nutzers an
              einen Server von Google in den USA übertragen und dort gekürzt.
              Die IP-Anonymisierung ist auf unseren Webseiten aktiv.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item cursor="2.3" text="Kontaktformulare/ E-Mail-Kontakt" />
            </p>
            <p>
              Bei der Kontaktaufnahme (z.B. über die angegebene E-Mail-Adresse)
              verarbeiten wir die E-Mail- Adresse und alle über das
              Kontaktformular der Nachricht selbst bekannt gegebenen
              personenbezogenen Daten (z.B. Ihren Namen).
            </p>
            <p>
              Wenn Sie ein Kontaktformular verwenden, verarbeiten wir zusätzlich
              die personenbezogenen Daten, die Sie im Kontaktformular angeben:
              Name, Adresse, Telefonnummer und weitere Kategorien von
              personenbezogenen Daten, die das Kontaktformular erfordert.
            </p>
            <p>
              Die Verarbeitung basiert auf Art. 6 (1) lit. f GDPR. Zweck und
              unser berechtigtes Interesse ist die Beantwortung Ihrer Anfrage
              und ggf. von Folgefragen.
            </p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item cursor="2.4" text="Änderungen" />
            </p>
            <p>
              Wir können diese Datenschutzerklärung ändern. Achten Sie darauf,
              dass Sie diese Datenschutzerklärung von Zeit zu Zeit überprüfen.
            </p>
            <p>Letzte Änderung: 19.11.2020</p>
          </div>
          <div className="col-12 mt-3">
            <p className="font-weight-bold">
              <Item
                cursor="2.4"
                text="Informationen über Ihr Widerspruchsrecht nach Art. 21 Allgemeine Datenschutzverordnung (GDPR):"
              />
            </p>
            <p>
              Sie haben das Recht, der Verarbeitung der Sie betreffenden
              personenbezogenen Daten aus Gründen, die sich auf Ihre besondere
              Situation beziehen, jederzeit auf der Grundlage von Art. 6 (1)
              lit. f GDPR (Verarbeitung personenbezogener Daten auf der
              Grundlage eines Interessenausgleichs) zu widerrufen; dazu gehört
              auch die Profilerstellung auf der Grundlage dieser Bestimmungen
              (Art. 4 Nr. 4 GDPR).
            </p>
            <p>
              Sollten Sie sich entscheiden, der Verarbeitung zu widersprechen,
              werden wir die Verarbeitung der Sie betreffenden personenbezogenen
              Daten einstellen, es sei denn, wir können zwingende berechtigte
              Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
              Rechte und Freiheiten außer Kraft setzen oder die Verarbeitung
              dient der Begründung, Ausübung oder Verteidigung von
              Rechtsansprüchen.
            </p>
            <p>
              Sie haben auch das Recht, der Verarbeitung der Sie betreffenden
              personenbezogenen Daten zu Werbezwecken jederzeit zu
              widersprechen; dies gilt auch für die Erstellung von Profilen,
              soweit sie mit der Werbung in Verbindung stehen.
            </p>
            <p>
              Sollten Sie sich entscheiden, der Verarbeitung zu Werbezwecken zu
              widersprechen, werden wir die Verarbeitung der Sie betreffenden
              personenbezogenen Daten für diese Zwecke einstellen.
            </p>
            <p>
              Der Widerspruch ist formlos. Im Idealfall sollte sie bei den in
              Abschnitt I. 1 genannten Stellen hinterlegt werden.
            </p>
          </div>
        </div>
      </DocumentScreen>
    </>
  );
}

export default Datenschutzerklärung;
