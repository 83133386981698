import React from "react";
import DocumentScreen from "../../screens/DocumentScreen/DocumentScreen";
import styles from "./Nutzungsbedingungen.module.scss";
import { NavLink } from "react-router-dom";

const Item = ({ cursor, text, margin, children }) => {
  return (
    <>
      <span className={`${margin ? margin : "mr-3"}`}>{cursor}</span>&nbsp;
      {text}
      {children}
    </>
  );
};

const NutzungsbedingungenScreen = () => {
  return (
    <DocumentScreen>
      <div className="row">
        <div className="col-12">
          <h2 className="display-3 text-center mb-2">Nutzungsbedingungen</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h4 className="text-center">carValoo WebApp, carValoo GmbH</h4>
          <h5 className="text-center text-muted mt-3">Stand 23.10.2020</h5>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <p className="font-weight-bold">Präambel</p>
        </div>
        <div className="col-12">
          <p className={"font-italic"}>
            Bitte lesen Sie diese Nutzungsbedingungen sorgfältig durch, bevor
            Sie mit der Nutzung der App fortfahren. Durch Ihre Registrierung bei
            der App melden Sie sich für deren Nutzung an; mit Ihrer Zustimmung
            zu diesen Nutzungsbedingungen kommt ein Vertrag über die Nutzung der
            App zwischen Ihnen (nachfolgend „Nutzer“ oder „Sie“) und der
            carValoo GmbH mit Sitz der Gesellschaft in 45143 Essen. ThyssenKrupp
            Allee 1 (nachfolgend „carValoo“ zustande).
          </p>
        </div>
        <div className="col-12">
          <p className="font-italic">
            Die App ermöglicht es Nutzern, welche einen gültigen Servicevertrag
            für die carValoo Cloudlösung besitzen, sich schnell und einfach
            Informationen bzgl. des Zustands ihrer Fahrzeugflotte, einzelner
            Fahrzeuge und/ oder einzelner Schadenereignisse anzeigen zu lassen
            bzw. aufkommende Schadenfälle zu verwalten. Die carValoo GmbH ist
            als Betreiber nach Maßgabe dieser Nutzungsbedingungen verantwortlich
            für die App, insb. für deren Inhalte und die durch die App
            angebotenen Dienste.
          </p>
        </div>
        <div className="col-12">
          <p className="font-weight-bold">
            Diese App richtet sich ausschließlich an Nutzer, die Unternehmer
            i.S.d. § 14 BGB sind. Eine Nutzung durch Verbraucher i.S.d. § 13
            BGB, d.h. jede natürliche Person, die ein Rechtsgeschäft zu Zwecken
            abschließt, das überwiegend weder ihrer gewerblichen noch ihrer
            selbständigen beruflichen Tätigkeit zugerechnet werden kann, wird
            ausgeschlossen.
          </p>
        </div>
        <div className="col-12">
          <p>
            Nutzerberechtigt sind ausschließlich unbeschränkt geschäftsfähige
            Personen.
          </p>
        </div>
        <div className="col-12">
          <p className="font-italic">
            Möchten Sie die App im Rahmen Ihrer Tätigkeit als Organ oder
            Arbeitnehmer einer Gesellschaft (nachfolgend „Ihr Unternehmen“),
            nutzen, welche Dienstleistungen bei der carValoo GmbH bezieht bzw.
            künftig beziehen möchte, so versichern Sie mit Ihrer Zustimmung,
            dass Sie berechtigt sind, Ihr Unternehmen bei der Vereinbarung
            dieser Nutzungsbedingungen entsprechend zu vertreten und diese
            verbindlich im Namen Ihres Unternehmens abzuschließen. In diesem
            Fall schließen die Begriffe „Nutzer“ und „Sie“ (bzw. die jeweiligen,
            grammatikalischen Formen dieser Begriffe) neben Ihnen selbst auch
            Ihr Unternehmen mit ein.
          </p>
        </div>
        <div className="col-12">
          <p className="font-italic">
            Die Überlassung der Nutzungsrechte an der App und ihren Funktionen
            zu ihrer Nutzung erfolgt im Wege der Leihe, d.h. unentgeltlich, aber
            zeitlich begrenzt auf unbestimmte Zeit. Die carValoo GmbH ist
            berechtigt, diese Nutzungsbedingungen jederzeit ohne Einhaltung
            einer Frist zu kündigen; dies umfasst auch das Recht zu einer
            Änderungskündigung.
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <p className="font-weight-bold">
            <Item
              cursor="§ 1"
              text="Geltungsbereich; Nutzungszweck; Leistungen"
            />
          </p>
        </div>
        <div className="col-12">
          <p>
            <Item
              cursor="(1)"
              text="Die vorliegenden Nutzungsbedingungen gelten in der jeweiligen Fassung für die Nutzung der
                App und der von der carValoo GmbH an den Nutzer zur Verfügung gestellten Leistungen und
                dem damit geschlossenen Vertrag zwischen der carValoo GmbH und dem Nutzer. Die
                Nutzungsbedingungen gelten auch für alle Updates und Programmergänzungen der App, die
                von der carValoo GmbH bereitgestellt werden. Alle Rechte sind der carValoo GmbH
                vorbehalten."
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="§ 312i Abs. 1 S. 1 Nr. 1 - 3 des Bürgerlichen Gesetzbuches findet keine Anwendung."
            />
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Das jeweilige Unternehmen oder die öffentliche Körperschaft wird durch den Nutzer vertreten
                und muss sich dessen Handeln und Wissen zurechnen lassen."
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Der Nutzer sichertzu, zur Vertretung seines Unternehmens, insb. in Hinblick auf den Abschluss
                dieser Nutzungsbedingungen, berechtigt zu sein."
            />
          </p>
          <p>
            <Item
              cursor="(5)"
              text="Die Nutzung der App ist ausschließlich registrierten Nutzern gemäß 0 zugänglich. "
            />
          </p>
          <p>
            <Item
              cursor="(6)"
              text="Die App dient dem Zweck, Nutzern Informationen über den Zustand der Fahrzeugflotte
                insgesamt sowie einzelner Fahrzeuge und Schadenereignisse zu geben. Die Nutzung der App
                ermöglicht dem Nutzer, sich zu jeder Zeit über den aktuellen Status bzw. den Zustand von
                Fahrzeugen in der eigenen Flotte sowie über Schaden- oder Missbrauchsereignisse der
                Fahrzeuge zu informieren und diese zu verwalten. Voraussetzung für die Nutzung der App ist
                ein wirksamer Vertrag zwischen dem Nutzer und der carValoo GmbH gem. § 2."
            />
          </p>
          <p>
            <Item
              cursor="(7)"
              text="Die App ist modular aufgebaut und deren Inhalt in Kacheln je Informationselement
                gegliedert. Im Modul „Übersicht“ erhält der Nutzer eine aggregierte Auswertung zum
                Zustand der gesamten Fahrzeugflotte. Im Modul „Fahrzeug“ kann der Nutzer die
                Zustandshistorie einzelner Fahrzeuge ansehen und diese verwalten. Im Modul „Ereignisse“
                kann der Nutzer aufkommende, potentielle Schadenereignisse abrufen, bewerten und
                verwalten. In den jeweiligen Modulen werden die Detailinformationen in Kacheln dargestellt.
                In den Kacheln werden z.B. Detailinformationen zu Fahrzeugposition, zu einem
                Schadenereignis, fahrzeugspezifische Dokumente, Bilder und Notizen sowie eine aggregierte
                Fahrstilbewertung je Fahrzeug oder die Schadenposition bzw. –intensität dargestellt. Der
                Nutzer kann per Mausklick bzw. Touch durch die App navigieren."
            />
          </p>
          <p>
            <Item
              cursor="(8)"
              text="Die Informationen, die die carValoo GmbH dem Nutzer innerhalb der App zur Verfügung
                stellt, werden mit größtmöglicher Sorgfalt zusammengestellt und laufend aktualisiert. Trotz
                sorgfältigster Kontrolle kann die Fehlerfreiheit der Inhalte dieser App nicht garantiert
                werden. Die carValoo GmbH schließt daher jede Haftung oder Garantie hinsichtlich der
                Genauigkeit, Vollständigkeit und Aktualität der innerhalb dieser App bereitgestellten
                Informationen aus. Die Inhalte stellen keine Zusage oder Garantie im Hinblick auf die
                Produkte der carValoo dar, insbesondere im Hinblick auf ihre Verkehrsfähigkeit oder ihre
                vertragliche Eignung für einen bestimmten Zweck."
            />
          </p>
          <p>
            <Item
              cursor="(9)"
              text="Sowohl die Überlassung der App als auch die Nutzung der darin angebotenen Dienste erfolgt
                durch die carValoo GmbH im Rahmen des gültigen Servicevertrags zwischen der carValoo
                GmbH und dem Nutzer. Die carValoo GmbH behält sich vor, bestimmte zusätzliche Funktionen
                kostenpflichtig anzubieten. Die Überlassung der Nutzungsrechte an der App und ihren
                Funktionen kann durch die carValoo GmbH jederzeit ohne Einhaltung einer Frist widerrufen
                bzw. gekündigt werden."
            />
          </p>
          <p>
            <Item
              cursor="(10)"
              text="Die carValoo GmbH ist berechtigt, jederzeit den Betrieb der App ganz oder teilweise
                einzustellen. Aufgrund der Beschaffenheit des Internets und/oder von Computersystemen
                übernimmt die carValoo GmbH keine Gewähr für die dauerhafte Verfügbarkeit der App."
            />
          </p>
          <p>
            <Item
              cursor="(11)"
              text="Betreiber und entsprechend verantwortlich für die App, insb. für deren Inhalte und der damit
                angebotenen Dienste, ist ausschließlich die carValoo GmbH nach Maßgabe dieser
                Nutzungsbedingungen."
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 2" text="Vertragsschluss" />
          </p>
          <p>
            Zwischen dem Nutzer und der carValoo GmbH kommt durch die
            Nutzerregistrierung gem. § 4 ein Vertrag auf Grundlage dieser
            Nutzungsbedingungen zustande. Die Unternehmensdaten der carValoo
            GmbH finden Sie im{" "}
            <NavLink activeClassName="menu-active" to="/impressum">
              Impressum.
            </NavLink>
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 3" text="Technische Anforderungen" />
          </p>
          <p>
            Für die Nutzung der App ist ein Internetzugang sowie ein
            entsprechender Webbrowser erforderlich. Die carValoo GmbH ist nicht
            für die Einhaltung der Systemanforderungen verantwortlich. Diese
            obliegen ausschließlich dem Nutzer.
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 4" text="Registrierungsprozess/Zugang zur App" />
          </p>
          <p>
            Der Zugang zur Nutzung der App kann mit drei unterschiedlichen
            Berechtigungsmöglichkeiten erfolgen:
          </p>
          <p className="ml-3">
            <Item
              cursor="(1)"
              text="Kunden-Administrator (nachfolgend „Kunden-Admin“, z.B. Flottenmanager)"
            />
          </p>
          <p>Standard-Nutzer</p>
          <p className="ml-3">
            <Item cursor="(2)" text="Anwender (z.B. Schadenmanager)" />
          </p>
          <p className="ml-3">
            <Item cursor="(3)" text="Betrachter (z.B. Fahrzeug Techniker)" />
          </p>
          <p>
            Alle Berechtigungskonzepte haben hierbei unterschiedliche Pflichten
            und Rechte, welche im Nachfolgenden erläutert werden:
          </p>
          <p className="font-weight-bold">
            <Item
              cursor="§ 5"
              text="gesonderte Rechte und Pflichten des Kunden-Administrators"
            />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Der Zugang zu der App und deren Funktionen bedarf einer Freischaltung berechtigter Nutzer
                durch den Kunden-Admin bei carValoo. Hierfür muss der Kunden-Admin einen Antrag auf
                Registrierung einzelner Nutzer bei der carValoo GmbH stellen."
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Für die Nutzung der App werden dem Nutzer von der carValoo GmbH zwei KundenAdministratoren-Zugänge bereitgestellt. Zur Registrierung muss der vollständige Name sowie
                die gültige Firmen-E-Mailadresse des Nutzers an die carValoo GmbH übermittelt werden. Die
                Kunden-Administratoren sind verpflichtet, zur Registrierung wahrheitsgemäße Angaben zu
                machen. Bei etwaigen späteren Änderungen der Nutzerdaten oder -berechtigungen sind diese
                der carValoo GmbH unter der E-Mailadresse carvalooapp-support@carValoo.com
                unverzüglich mitzuteilen."
            />
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Nach erfolgter Registrierung erhalten die berechtigten Nutzer (Kunden-Admin und StandardNutzer) jeweils einen Aktivierungslink, mit dem Sie einen persönlichen Zugang für die App
                erstellen können. Nur zuvor registrierte Nutzer können einen persönlichen Zugang erstellen
                und entsprechend ihrer Rolle Informationen in der App abrufen. Nutzer verpflichten sich bei
                erstmaliger Nutzung ein nur ihnen bekanntes Passwort zu vergeben."
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Mit der Erstanmeldung der Nutzer in der App muss eine Einwilligung der
                Nutzungsbedingungen und Datenschutzerklärung erfolgen."
            />
          </p>
          <p>
            <Item
              cursor="(5)"
              text="Der Kunden-Admin ist befugt, per schriftlichem Hinweis an carValoo per E-Mail an
                carvalooapp-support@carValoo.com Anwender- oder Betrachter-Zugänge zu erstellen, zu
                löschen, Nutzungsrechte für diese zu erteilen und die Aktivitäten (Protokoll der Log Zeiten)
                dieser einzusehen. Diese Befugnis gilt allein für die von der carValoo erstellten AdministratorZugänge. Anwender oder Betrachter haben hingegen eine reine Einsichtsbefugnis mit
                eingeschränkter Möglichkeit, aufkommende Schadenfälle in der App zu bearbeiten.
                Betrachter können nur ausgewählte Informationen zu einzelnen Fahrzeugen oder
                Schadenfällen einsehen."
            />
          </p>
          <p>
            <Item
              cursor="(6)"
              text="Soweit der Kunden-Administrator Kenntnis davon erlangt, dass Dritte seine Benutzerdaten
                missbräuchlich verwenden, ist er verpflichtet, die carValoo unverzüglich schriftlich zu
                unterrichten (E-Mail an carvalooapp-support@carValoo.com). Die carValoo GmbH wird
                diesen Zugang zum passwortgeschützten Bereich unverzüglich sperren. Bei Ausscheiden aus
                dem Unternehmen ist der Kunden-Administrator verpflichtet, die carValoo GmbH
                unverzüglich über sein Ausscheiden zu unterrichten, sodass diese seinen Zugang sperren
                kann. Kommt der Kunden-Administrator selber dieser Pflicht nicht nach, muss die carValoo
                GmbH auf anderem Wege darüber informiert werden. Dies kann durch jede Person erfolgen,
                die davon Kenntnis hat."
            />
          </p>
          <p>
            <Item
              cursor="(7)"
              text="Der Kunden-Admin trägt die Verantwortung für die Geheimhaltung der Passwörter seiner
                Standard-Nutzer. Der Kunden-Admin wird seine Nutzer anweisen, ihre Passwörter geheim zu
                halten und sie nicht an andere weiterzugeben oder zuzulassen, dass sie an Dritte
                weitergegeben werden können, und wird die notwendigen Maßnahmen ergreifen, um die
                Geheimhaltung der Passwörter sicherzustellen. Der Kunden-Admin haftet für alle unter den
                Benutzerdaten vorgenommenen Aktivitäten."
            />
            <p className="my-2"></p>
            <p>
              Soweit der Kunden-Admin Kenntnis davon erlangt, dass Dritte die
              Benutzerdaten seiner Nutzer benutzen, ist der Kunden-Admin selber
              dazu verpflichtet, diesen Zugang über carValoo sperren zu lassen.
              Dies ist unmittelbar an carvalooapp-support@carValoo.com zu
              melden.
            </p>
          </p>
          <p>
            <Item
              cursor="(7)"
              text="Scheidet ein Mitarbeiter, der über einen Nutzer-Zugang verfügt, aus dem Unternehmen aus,
                ist der Kunden-Admin verpflichtet, carValoo unverzüglich mit der Löschung zu beauftragen."
            />
          </p>
          <p className="font-weight-bold">
            <Item
              cursor="§ 6"
              text="Allgemeine Pflichten des Nutzers (Kunden-Admin und Standardnutzer)"
            />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Die Allgemeinen Pflichten gelten für alle Nutzer dieser App (Nutzer und Kunden-Admin)."
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Die für die Durchführung dieses Vertrages erforderlichen Daten sind vollständig und
                wahrheitsgemäß durch den Nutzer anzugeben. "
            />
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Der Nutzer ist verpflichtet, jederzeit seine Zugangsdaten zu der App streng vertraulich zu
                halten und nicht an Dritte weiterzugeben. Der Nutzer ist unbeschränkt verantwortlich, falls es
                durch seine Handlungen und/oder Unterlassungen dazu kommt, dass von ihm nicht oder nicht
                mehr autorisierte Personen, Dritte und/oder ausgeschiedene Mitarbeiter die Zugangsdaten
                benutzen bzw. benutzen können."
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Ebenso ist der Nutzer verpflichtet, Daten und Inhalte, die ihm über die App zur Verfügung
                gestellt werden, vertraulich zu behandeln. Er hat dafür Sorge zu tragen, dass die Daten und
                Informationen keinen unbefugten Dritten zugänglich werden, soweit dies nicht durch
                zwingende gesetzliche Regelungen erforderlich ist."
            />
          </p>
          <p>
            <Item
              cursor="(5)"
              text="Der Nutzer darf bei Nutzung der App nicht"
            />
          </p>
        </div>
        <div className={`${styles.bullets} col-12 pl-5`}>
          <p>
            <Item
              margin="mr-1"
              cursor="●"
              text="mit seinem Nutzungsverhalten gegen die guten Sitten verstoßen;"
            />
          </p>
          <p>
            <Item
              margin="mr-1"
              cursor="●"
              text="gewerbliche Schutz-, Urheber-, Persönlichkeits-, Eigentumsrechte oder sonstige Rechte
                Dritter verletzen;"
            />
          </p>
          <p>
            <Item
              margin="mr-1"
              cursor="●"
              text="Inhalte mit Viren, sog. Trojanischen Pferden oder sonstige Programmierungen, die
                Software beschädigen können, übermitteln;"
            />
          </p>
          <p>
            <Item
              margin="mr-1"
              cursor="●"
              text="Hyperlinks oder Inhalte eingeben, speichern oder senden, zu denen er nicht befugt ist,
                insbesondere wenn diese Hyperlinks oder Inhalte gegen Geheimhaltungsverpflichtungen
                verstoßen oder rechtswidrig sind; oder"
            />
          </p>
          <p>
            <Item
              margin="mr-1"
              cursor="●"
              text="Werbung oder unaufgeforderte E-Mails (sogenannten 'Spam') oder unzutreffende
                Warnungen vor Viren, Fehlfunktionen und dergleichen verbreiten oder zur Teilnahme an
                Gewinnspielen, Schneeballsystemen, Kettenbrief-, Pyramidenspiel- und vergleichbaren
                Aktionen auffordern."
            />
          </p>
        </div>
        <div className="col-12">
          <p>
            <Item
              cursor="(6)"
              text="Die carValoo GmbH darf dem Nutzer den Zugang zu der App jederzeit sperren bzw. diesen deregistrieren, insbesondere wenn der Nutzer gegen seine Pflichten aus diesen Bedingungen
                verstößt. In diesem Fall kündigt die carValoo GmbH durch die Vornahme der entsprechenden
                Handlung konkludent diesen Vertrag mit dem Nutzer; dem Nutzer ist die weitere Nutzung der
                App mit sofortiger Wirkung untersagt."
            />
          </p>
          <p>
            <Item
              cursor="(7)"
              text="Der Nutzer haftet gegenüber der carValoo GmbH für Schäden, die durch eine Pflichtverletzung
                aus diesen Nutzungsbedingungen entstehen. Der Nutzer ist zudem verpflichtet, die carValoo
                GmbH von Ansprüchen Dritter freizustellen, die dieser durch eine Pflichtverletzung des
                Nutzers entstehen."
            />
          </p>
          <p className="font-weight-bold">
            <Item
              cursor="§ 7"
              text="Allgemeine Mitwirkungspflichten des Nutzers (Nutzer und Kunden-Admin)"
            />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Die Allgemeinen Mitwirkungspflichten gelten für alle Nutzer dieser App (Standard-Nutzer und
                  Kunden-Admin)."
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Bei der Verwendung der App hat der Nutzer die für einen Gebrauch notwendigen
                Sorgfaltspflichten einzuhalten. Insbesondere ist er verpflichtet, regelmäßig eine Sicherung
                seiner Daten vorzunehmen, damit diese im Falle eines Verlusts wiederhergestellt werden
                können"
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 8" text="Nutzungsrechte; Geistiges Eigentum" />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Für die Nutzung der über die App vermittelten Dienste erwirbt der Nutzer für sich das einfache,
nicht ausschließliche, auf Dritte nicht übertragbare und befristete Nutzungsrecht an der App
und den vermittelten Inhalten in dem Umfang, wie dies vereinbart ist oder, falls nichts
vereinbart ist, wie es dem mit der Bereitstellung und Überlassung durch uns verfolgten Zweck
entspricht"
            />
          </p>
          <p>
            <Item cursor="(2)">
              Ungeachtet der Bestimmungen unter 0{" "}
              <span className="font-weight-bold">
                Error! Reference source not found
              </span>
              . und 0 (1) sind die über die App angebotenen Inhalte
              urheberrechtlich geschützt. Soweit nicht anders angegeben, sind
              zudem alle in der App verwendeten Marken, Firmenlogos und
              Kennzeichen markenrechtlich geschützt. Die carValoo GmbH stellt
              diese ausschließlich für eigene, nicht kommerzielle Zwecke des
              Nutzers zur Verfügung. Insbesondere dürfen weder Informationen,
              Software noch Dokumentationen vom Nutzer zu irgendeiner Zeit an
              Dritte vertrieben, vermietet oder in sonstiger Weise überlassen
              werden. Jede darüberhinausgehende Nutzung ist ausgeschlossen und
              bedarf der vorherigen gesonderten schriftlichen Einwilligung durch
              die carValoo GmbH. Insbesondere dürfen die Inhalte ohne Zustimmung
              der carValoo GmbH nicht verändert, kopiert, wiederveröffentlicht,
              übertragen, verbreitet oder gespeichert werden.
            </Item>
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Außerhalb der hierin ausdrücklich eingeräumten Nutzungsrechte oder sonstiger Rechte,
                      werden dem Nutzer keine weiteren Rechte gleich welcher Art, insbesondere an dem
                      Firmennamen und an gewerblichen Schutzrechten, wie Patenten, Gebrauchsmustern oder
                      Marken eingeräumt, noch trifft die carValoo GmbH eine entsprechende Pflicht, derartige
                      Rechte einzuräumen."
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Soweit der Nutzer auf der App Ideen und Anregungen hinterlegt, darf die carValoo GmbH diese
                zur Entwicklung, Verbesserung und zum Vertrieb unentgeltlich verwerten sowie gewerbliche
                Schutzrechte darauf anmelden."
            />
          </p>
          <p>
            <Item
              cursor="(5)"
              text="Es ist dem Nutzer nicht gestattet, die App bzw. die Nutzungsrechte daran zu vertreiben oder
                anderweitig Dritten zu übertragen (einschließlich der Vermietung, Verpachtung, Leihgabe oder
                Unterlizenzierung). Es ist ferner untersagt, den Programmcode der App oder Teile hiervon zu
                verändern, rückwärts zu entwickeln (Reverse Engineering), zu dekompilieren, zu
                disassemblieren oder den Quellcode auf andere Weise festzustellen sowie abgeleitete Werke
                der App zu erstellen, sofern das Gesetz dies nicht ausdrücklich erlaubt."
            />
          </p>
          <p>
            <Item
              cursor="(6)"
              text="Die §§ 69a ff. Urheberrechtsgesetz bleiben im Übrigen unberührt."
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 9" text="Haftung für Sach- und Rechtsmängel" />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Sofern Informationen, Software oder Inhalte unentgeltlich überlassen werden, ist eine
                Haftung für Sach- und Rechtsmängel der Informationen, Software und Inhalte, insbesondere
                für deren Richtigkeit, Aktualität, Fehlerfreiheit, Freiheit von Schutz- und Urheberrechten
                Dritter, Vollständigkeit und/oder Verwendbarkeit – außer bei Vorsatz, grober Fahrlässigkeit
                oder Arglist – ausgeschlossen. "
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Die Informationen in der App können Spezifikationen oder allgemeine Beschreibungen
                technischer Möglichkeiten von Waren enthalten, welche im Einzelfall (z.B. aufgrund von
                Produktänderungen) nicht immer vorliegen müssen. Die gewünschten Leistungsmerkmale der
                Ware sind daher im Einzelfall beim Kauf zu vereinbaren."
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 10" text="Sonstige Haftung" />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Die Haftung der carValoo GmbH für Sach- und Rechtsmängel richtet sich nach den
                Bestimmungen in 0 dieser Nutzungsbedingungen. "
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Im Übrigen ist jegliche Haftung der carValoo GmbH insb. für aber nicht limitiert auf
                Folgeschäden ausgeschlossen, soweit nicht z.B. nach dem Produkthaftungsgesetz, wegen
                Vorsatzes, grober Fahrlässigkeit, wegen Verletzung des Lebens, des Körpers oder Gesundheit,
                wegen der Übernahme einer Beschaffenheitsgarantie, wegen arglistigen Verschweigens eines
                Mangels oder wegen der Verletzung wesentlicher Vertragspflichten zwingend gehaftet wird.
                Der Schadensersatz wegen Verletzung wesentlicher Vertragspflichten ist jedoch auf den
                vertragstypischen, vorhersehbaren Schaden begrenzt, soweit nicht Vorsatz oder grobe
                Fahrlässigkeit vorliegt."
            />
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Obgleich die carValoo GmbH bemüht ist, die App virenfrei zu halten, kann die carValoo GmbH
                keine Virenfreiheit garantieren. Vor dem Herunterladen von Informationen wird der Nutzer
                zum eigenen Schutz sowie zur Verhinderung von Viren auf der App für angemessene
                Sicherheitsvorrichtungen und Virenscanner sorgen."
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Die carValoo GmbH übernimmt keine Gewähr dafür, dass die App oder die hierüber
                vermittelten Inhalte mit der Hardware des Endgerätes des Nutzers vereinbar sind. Die
                carValoo GmbH übernimmt darüber hinaus keine Gewähr dafür, dass diese Inhalte jederzeit
                oder zu bestimmten Zeiten uneingeschränkt zur Verfügung stehen oder gewisse Leistungs- und
                Funktionsanforderungen erfüllen."
            />
          </p>
          <p>
            <Item
              cursor="(5)"
              text="Die carValoo GmbH behält sich vor, das Angebot der App oder der darüber vermittelten Inhalte
                ganz oder teilweise zeitweise oder dauerhaft einzustellen."
            />
          </p>
          <p>
            <Item
              cursor="(6)"
              text="Die carValoo GmbH haftet nicht für Schäden, die durch Fehler, Verzögerungen oder
                Unterbrechungen in der Übermittlung, bei Störungen der technischen Anlagen und des
                Service, unrichtige oder unvollständige Daten, Viren oder in sonstiger Weise bei der Nutzung
                der App entstehen, soweit sie nicht auf Vorsatz oder grober Fahrlässigkeit beruhen."
            />
          </p>
          <p>
            <Item
              cursor="(7)"
              text="Die carValoo GmbH ist nur für solche Inhalte verantwortlich, die von der carValoo GmbH selbst
                erstellt, veröffentlicht oder verbreitet worden sind."
            />
          </p>
          <p>
            <Item
              cursor="(8)"
              text="Eine Änderung der Beweislast zum Nachteil des Nutzers ist mit den vorstehenden Regelungen
                nicht verbunden."
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 11" text="Datenschutz" />
          </p>
          <p>
            <Item cursor="(1)">
              Mit der Nutzung der App erklärt sich der Nutzer mit der Geltung
              der Datenschutzerklärung (abrufbar unter{" "}
              <a href="www.carValoo.com/Datenschutzerklaerung" target="_blank">
                www.carValoo.com/Datenschutzerklaerung
              </a>{" "}
              ) einverstanden.
            </Item>
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Daten des Nutzers, welche die carValoo GmbH im Rahmen der Nutzung der App oder der
Registrierung erlangt, werden nach Maßgabe der Datenschutzerklärung gespeichert,
verarbeitet und verwendet."
            />
          </p>
          <p className="font-weight-bold">
            <Item
              cursor="§ 12"
              text="Vertragslaufzeit und Kündigung der Nutzungsbedingungen"
            />
          </p>
          <p>
            <Item
              cursor="(1)"
              text="Der Vertrag tritt mit der Nutzerregistrierung und Annahme der Nutzungsbedingungen
                zwischen der carValoo GmbH und dem Nutzer in Kraft und läuft auf unbestimmte Zeit. "
            />
          </p>
          <p>
            <Item
              cursor="(2)"
              text="Die carValoo GmbH ist berechtigt, diese Nutzungsbedingungen jederzeit ohne Einhaltung einer
                Frist oder Form sowohl ausdrücklich als auch konkludent, etwa durch De-Registrierung oder
                Zugangssperrung des Nutzers, zu kündigen, ohne dass es des Zugangs einer
                Kündigungserklärung bedarf; dies umfasst auch das Recht zu einer Änderungskündigung im
                Sinne des 0."
            />
          </p>
          <p>
            <Item
              cursor="(3)"
              text="Der Nutzer ist berechtigt, diese Nutzungsbedingungen jederzeit ohne Einhaltung einer Frist
                durch De-Registrierung zu kündigen, ohne dass es des Zugangs einer Kündigungserklärung
                bedarf. Hierfür genügt das Versenden einer formlosen E-Mail mit der Bitte um DeRegistrierung an den jeweiligen Kundenbetreuer. "
            />
          </p>
          <p>
            <Item
              cursor="(4)"
              text="Mit Wirksamwerden der Kündigung hat der Nutzer die Nutzung der App einzustellen. "
            />
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 13" text="Änderungen der Nutzungsbedingungen" />
          </p>
          <p>
            Die carValoo GmbH behält sich das Recht vor, diese
            Nutzungsbedingungen jederzeit ohne Angabe von Gründen zu ändern. Die
            Änderung wird bekanntgeben, indem die carValoo GmbH eine geänderte
            Fassung in der App hinterlegt. Wenn eine Änderung der
            Nutzungsbedingungen erfolgt ist, wird dem Nutzer ein entsprechender
            Hinweis bei erstem Aufruf der App nach Änderung der
            Nutzungsbedingungen als „Pop-up“ angezeigt. Indem der Nutzer nach
            Bekanntgabe der neuen Nutzungsbedingungen weiterhin die Funktionen
            der App nutzt, erklärt er automatisch sein Einverständnis zu den
            jeweils geänderten neuen Nutzungsbedingungen.
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 14" text="Geltendes Recht, Gerichtsstand" />
          </p>
          <p>
            Es gilt das materielle Recht der Bundesrepublik Deutschland unter
            Ausschluss des UN-Kaufrechts. Für Nutzer, die keine Verbraucher sind
            oder die keinen ordentlichen Gerichtsstand in Deutschland haben, ist
            der Gerichtsstand für alle Streitigkeiten, die durch oder in
            Verbindung mit dieser Nutzungsbedingung entstehen, Krefeld
            (Deutschland).
          </p>
          <p className="font-weight-bold">
            <Item cursor="§ 15" text="Sonstiges" />
          </p>
          <p>
            Sollten einzelne Bestimmungen dieser Nutzungsbedingung unwirksam
            oder undurchführbar sein oder werden, bleibt die Wirksamkeit der
            anderen Bedingungen im Übrigen unberührt.
          </p>
        </div>
      </div>
    </DocumentScreen>
  );
};

export default NutzungsbedingungenScreen;
