import React, { useState, useEffect, useCallback } from "react";

function useLocalStorageDetect(key) {
  const [target, setTarget] = useState(localStorage.getItem(key));

  const onStorage = useCallback(
    (params) => {
      setTarget(localStorage.getItem(key));
    },
    [key]
  );

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("storage", onStorage, false);
    } else {
      window.attachEvent("onstorage", onStorage);
    }

    return () => {
      window.removeEventListener("storage");
      window.detachEvent("onstorage");
    };
  }, [onStorage]);

  return target;
}

export default useLocalStorageDetect;
