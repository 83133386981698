import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, permittedRoles, ...rest }) => {
  const currentRole = useSelector(
    (state) => state.UserReducer.roleData.roleName
  );

  if (!currentRole) {
    return (
      <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
    );
  }
  return permittedRoles.includes(currentRole) ? (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  ) : (
    <Redirect to="/unauthorized" />
  );
};

export default ProtectedRoute;
