import axios from "axios";

const ORGANIZATION_NAME = "organizationName";

function getUserName() {
  const organizationName = localStorage.getItem(ORGANIZATION_NAME);
  return organizationName;
}

const getScreen = () => {
  const pathname = window.location.pathname;
  if(pathname.includes('ereignisse')) return 'event';
  if(pathname.includes('fahrzeug')) return 'vehicle';
  if(pathname.includes('dashboard')) return 'dashboard';
  if(pathname.includes('search')) return 'search';
}

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use(function (config) {
  if (config.headers.SkipOrganizationName) {
    delete config.headers.SkipOrganizationName;
  } else {
    config.headers.OrganizationId = getUserName();
  }

  config.headers.language = !localStorage.getItem('rcml-lang') ? 'DE' : localStorage.getItem('rcml-lang').toUpperCase();
  config.headers.screen = getScreen();

  return config;
});

// axiosInstance.interceptors.response.use(function (response) {
//   return response
// }, function(error) {
//   if(error.response.status === '400') {
//     return error
//   }
//   window.location.href = "/error";
// })

export { axiosInstance };
