import {
  GET_ROLES_START,
  GET_ROLES_FAILED,
  GET_ROLES_SUCCESS,
} from "../types/user-types";

export const startGetRolesAction = () => ({ type: GET_ROLES_START });
export const getRolesSuccessAction = (rolesData) => ({
  type: GET_ROLES_SUCCESS,
  payload: rolesData,
});

export const getRolesFailedAction = () => ({ type: GET_ROLES_FAILED });
