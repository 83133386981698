import React from "react";
import { Spin } from "antd";

const LoaderOverlay = (props) => {
  return (
    props.visible && (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: 1000,
            opacity: 0.5,
            borderRadius: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "50%",
            transform: "translate(100%, -50%)",
            zIndex: 1001,
          }}
        >
          <Spin size="large" />
        </div>
      </>
    )
  );
};

export default LoaderOverlay;
