import React, { useLayoutEffect, useState, useCallback } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./App.scss";
import {
  startGetRolesAction,
  getRolesSuccessAction,
  getRolesFailedAction,
} from "./store/actions/user-actions";
import {
  setOrganizationConfig,
  initializeOrganizationScreenPermissions2,
} from "./store/actions/organization-config-actions";

import MainLayout from "./layout/MainLayout";
import SearchScreen from "./screens/SearchScreen";
import UnauthorizedScreen from "./screens/UtilityScreens/UnauthorizedScreen";
import ForbiddenScreen from "./screens/UtilityScreens/ForbiddenScreen";
import {
  getUserRoles,
  getUserProperties,
  getOrganizationScreenPermissions,
  getOrganizationScreenPermissions2,
} from "./services/user-service";
import { ConfigProvider, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

// Components
import ImpressumScreen from "./components/Impressum/impressum";
import NutzungsbedingungenScreen from "./components/Nutzungsbedingungen/Nutzungsbedingungen";
import Datenschutzerklärung from "./components/Datenschutzerklärung/Datenschutzerklärung";

// Language Service
import { useLanguageContext } from "./language-service/container/Language";
import MainLoader from "./components/MainLoader/MainLoader";

const Main = (props) => {
  const { antdLocale } = useLanguageContext();
  const dispatch = useDispatch();
  const rolesLoading = useSelector((state) => state.UserReducer.rolesLoading);
  const [organizationName, setOrganizationName] = useState();
  const [organizationScreenPermissions2, setOrganizationScreenPermissions2] =
    useState();
  function saveOrganizationNameToLocalStorageIfNotExist(organizations) {
    const currentOrganization = localStorage.getItem("organizationName");
    if (!organizations.includes(currentOrganization)) {
      localStorage.setItem("organizationName", organizations[0]);
    }
  }

  const fetchOrganizationScreenPermissions = useCallback(() => {
    getOrganizationScreenPermissions2().then(({ data }) => {
      setOrganizationScreenPermissions2(data);
      dispatch(initializeOrganizationScreenPermissions2(data));
    });
  }, [dispatch]);

  const saveOrganizationConfigToState = useCallback(
    (organizationGuide) => {
      const organizationName = localStorage.getItem("organizationName");
      const dispatchValueForOrganizationConfig = setOrganizationConfig({
        guide: organizationGuide,
      });
      dispatch(dispatchValueForOrganizationConfig);
      // set organization name for declaration app is ready
      setOrganizationName(organizationName);
    },
    [dispatch]
  );

  const saveOrganization = useCallback(
    ({ organizations }) => {
      saveOrganizationNameToLocalStorageIfNotExist(organizations);
      fetchOrganizationScreenPermissions();
    },
    [fetchOrganizationScreenPermissions]
  );

  const setUser = useCallback(
    (data) => {
      dispatch(getRolesSuccessAction(data));
    },
    [dispatch]
  );

  const getUser = useCallback(async () => {
    dispatch(startGetRolesAction());
    try {
      const { data: user } = await getUserRoles();
      saveOrganization(user);
      setUser(user);
    } catch (err) {
      dispatch(getRolesFailedAction());
      if (err.response) {
        if (err.response.status === 401) {
          // 401 error
          dispatch(push("/unauthorized"));
          // window.location.replace = `${window.location.host}/unauthorized`;
        } else if (err.response.status === 403) {
          // 403 error
          dispatch(push("/forbidden"));
          // window.location.replace = `${window.location.host}/unauthorized`;
        }
      }
    }
  }, []);

  const getUserPropertiesGuide = useCallback(async () => {
    try {
      const { data: organizationGuide } = await getUserProperties();
      saveOrganizationConfigToState(organizationGuide);
    } catch (error) {
      console.log(error);
    }
  }, [saveOrganizationConfigToState]);

  useLayoutEffect(() => {
    async function configurationUser() {
      await getUser();
      await getUserPropertiesGuide();
    }

    configurationUser();
  }, [getUser, getUserPropertiesGuide]);

  if (rolesLoading || !organizationName || !organizationScreenPermissions2)
    return <MainLoader />;

  return (
    <>
      <ConfigProvider locale={antdLocale}>
        <Switch>
          <Route path="/search" render={() => <SearchScreen />} />
          <Route path="/impressum" component={ImpressumScreen} />
          <Route
            path="/nutzungsbedingungen"
            component={NutzungsbedingungenScreen}
          />
          <Route
            path="/datenschutzerklärung"
            component={Datenschutzerklärung}
          />
          <Route path="/forbidden" component={ForbiddenScreen} />
          <Route path="/unauthorized" component={UnauthorizedScreen} />
          <Route path="/" render={() => <MainLayout />} />
        </Switch>
      </ConfigProvider>
    </>
  );
};

export default withRouter(Main);
