import React, { useEffect, useState } from "react";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCarCrash,
  faMapMarkerAlt,
  faClock,
  faUser,
  faCar,
} from "@fortawesome/pro-light-svg-icons";
import { convertSeverityToColor } from "../../../utility/severity-color-converter";
import IconSeverity from "../../Shared/IconSeverity";
import moment from "moment";
import { getEventDetailGenericType } from "../../../services/events-service";
import LoaderOverlay from "../../LoaderOverlay/LoaderOverlay";
import { getVehicleDetailGenericType } from "../../../services/vehicle-service";

// Configurations
moment.updateLocale(moment.locale("de"), { invalidDate: "unbekannt" });

const InfoCardGenericType = (props) => {
  const { eventId, vehicleId } = props;
  const history = useHistory();
  const [link, setLink] = useState();
  const [state, setState] = useState();

  useEffect(() => {
    if(eventId) {
      getEventDetailGenericType(eventId).then(({ data }) => setState(data));
      setLink(`/ereignisse/${eventId}`)
    } else if (vehicleId) {
      getVehicleDetailGenericType(vehicleId).then(({ data }) => setState(data));
      setLink(`/fahrzeug/${vehicleId}`)
    }
  }, [eventId, vehicleId]);

  const isLoading = !state || !state.data || !state.data.length;

  const loader = (
    <div style={{ height: "12rem" }}>
      <LoaderOverlay visible={true} />;
    </div>
  );

  return isLoading ? (
    loader
  ) : (
    <>
      <div
        className="p-3 d-flex flex-column justify-content-between"
        style={{ height: "100%" }}
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ fontSize: "14px" }}
        >
          <h6 className="mb-0">
            {state.title}
            <br />
            <span
              style={{
                fontSize: "13px",
                color: "#b2b2b2",
                marginTop: "-5px",
              }}
            >
              {state.subtitle}
            </span>
          </h6>
          {eventId && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <IconSeverity
                inSideColor={convertSeverityToColor(state.severity)}
                outSideColor={convertSeverityToColor(state.severity)}
                height={45}
                width={45}
              />
            </div>
          )}
        </div>
        <div>
          <ul className="fa-ul mt-4">
            {state.data?.map(({ name, value, icone }, index) => (
              <li className="my-3" key={index}>
                <span className="fa-li">
                  <i className={`fa-lg ${icone}`}></i>
                </span>
                <p style={{ color: "#484848" }} className="mb-0 pl-2">
                  {value || "-"}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <Link
          to={{
            pathname: link,
            state: { ...state },
          }}
          className="d-flex flex-column justify-content-between border-0 p-1"
          style={{
            width: "100%",
            borderBottom: "1px solid #dadada",
            position: "relative",
            ...(props.active && {
              boxShadow: "inset 0 0rem 1rem rgba(0,0,0,.075)",
            }),
          }}
        >
          <Button
            icon={<SearchOutlined />}
            type="primary"
            className="mt-2 mb-2"
            style={{
              width: "100%",
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <span style={{ marginTop: "1px" }}>Details</span>
          </Button>
        </Link>
      </div>
    </>
  );
};

export default InfoCardGenericType;
