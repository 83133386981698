import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import Main from "./Main";

// Language Service
import { LanguageProvider } from "./language-service/container/Language";

// REDUX
import { Provider } from "react-redux";
import configureStore, { history } from "./store/configureStore";
import { ConnectedRouter } from "connected-react-router";

const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <LanguageProvider>
        <ConnectedRouter history={history}>
          <Main />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  );
};

export default App;
