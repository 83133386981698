import React, { useContext, useEffect } from "react";
import { Select } from "antd";
import styled from "@emotion/styled";

import { languageOptions } from "../../language-service/languages";
import { useLanguageContext } from "../../language-service/container/Language";

// Styles
import "./LanguageSelector.module.scss";

const CustomSelect = styled(Select)`
  .ant-select-selection-item {
    padding: 0 !important;
  }
  span.ant-select-arrow {
    display: none !important;
  }
`;

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useLanguageContext();

  // set selected language by calling context method
  const handleLanguageChange = (value) => userLanguageChange(value, true);

  useEffect(() => {
    let defaultLanguage = window.localStorage.getItem("rcml-lang");
    if (!defaultLanguage) {
      defaultLanguage = window.navigator.language.substring(0, 2);
    }
    userLanguageChange(defaultLanguage);
  }, [userLanguageChange]);

  return (
    <>
      <CustomSelect
        onChange={handleLanguageChange}
        value={userLanguage}
        bordered={false}
      >
        {Object.entries(languageOptions).map(([id, country]) => (
          <Select.Option key={id} value={id}>
            <img
              height="20"
              width="20"
              style={{ borderRadius: "50%" }}
              src={country.flag}
              alt=""
            />
          </Select.Option>
        ))}
      </CustomSelect>
    </>
  );
}
