import React, { useState, useEffect, useRef, useCallback } from "react";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { useHistory, useLocation } from "react-router-dom";
import { Input, DatePicker, Divider, Select, Slider, Card, Button } from "antd";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import CompassOutlined from "@ant-design/icons/CompassOutlined";
import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import UnorderedListOutlined from "@ant-design/icons/UnorderedListOutlined";
import SearchMap from "../components/SearchMap/SearchMap";
import debounce from "../utility/debounce";
import {
  getPlaces,
  cancelTokenGetPlaces,
  getSearchEvents,
  getSearchVehicles,
  cancelTokenSearchEvents,
  cancelTokenSearchVehicles,
} from "../services/search-service";
import { getVehicletypes } from "../services/vehicle-service";
import { getEventSeverityList } from "../services/events-service";
import queryString from "query-string";
import moment from "moment";
import { convertSeverityToColor } from "../utility/severity-color-converter";
import locale from "antd/es/locale/de_DE";
import "moment/locale/de";
function formatter(value) {
  return `${value}KM`;
}

const ye = new Intl.DateTimeFormat("en", { year: "numeric" });
const mo = new Intl.DateTimeFormat("en", { month: "2-digit" });
const da = new Intl.DateTimeFormat("en", { day: "2-digit" });

const SearchScreen = (props) => {
  const [animationState, setAnimationState] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const eventsBlockContainer = useRef();
  const vehiclesBlockContainer = useRef();
  // MAP STATE
  const [eventsMapState, setEventsMapState] = useState(false);
  const [vehicleMapState, setVehicleMapState] = useState(false);

  // AVAILABLE LOCATIONS
  const [
    availableLocationSearchQuery,
    setAvailableLocationSearchQuery,
  ] = useState("");
  const [availableLocationsLoading, setAvailableLocationsLoading] = useState(
    false
  );
  const [availableLocations, setAvailableLocations] = useState([]);
  // VEHICLE TYPES
  const [vehicleTypesLoading, setVehicleTypesLoading] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  // EVENT TYPES
  const [eventTypesLoading, setEventTypesLoading] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  // OVERALL SEARCH STATE
  const [overallState, setOverallState] = useState({
    globalSearch: null,
    dateRange: null,
    vehicleType: "alle",
    place: null,
    radius: 50,
    eventType: "alle",
  });
  // EVENTS LIST
  const [eventsLoading, setEventsLoading] = useState(false);
  const [eventPage, setEventPage] = useState(1);
  const [eventsList, setEventsList] = useState([]);
  const [totalEventsCount, setTotalEventsCount] = useState(null);
  // VEHICLE LIST
  const [vehiclesLoading, setVehiclesLoading] = useState(false);
  const [vehiclePage, setVehiclePage] = useState(1);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalVehicleCount, setTotalVehicleCount] = useState(null);

  const overallStateSearchDebounce = (state) => {
    setEventsList([]);
    setVehicleList([]);

    if (eventsLoading) {
      cancelTokenSearchEvents();
    }
    setEventsLoading(true);
    getSearchEvents(state, 1, 10)
      .then((res) => {
        const { items: events, totalCount } = res.data;
        setEventsList(events);
        setTotalEventsCount(totalCount);
      })
      .finally((_) => setEventsLoading(false));

    if (vehiclesLoading) {
      cancelTokenSearchVehicles();
    }
    setVehiclesLoading(true);
    getSearchVehicles(state, 1, 10)
      .then((res) => {
        const { items: vehicles, totalCount } = res.data;
        setVehicleList(vehicles);
        setTotalVehicleCount(totalCount);
      })
      .finally((_) => setVehiclesLoading(false));
    setEventPage(1);
    setVehiclePage(1);

    if (
      queryString.stringify(state) !==
      "dateRange&eventType=alle&globalSearch&place&radius=50&vehicleType=alle"
    ) {
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify(state),
      });
    }
  };

  const availableLocationSearchDebounce = (locations) => {
    setAvailableLocationsLoading(true);
    getPlaces(locations)
      .then((res) => setAvailableLocations(res.data.candidates))
      .finally((_) => setAvailableLocationsLoading(false));

    return () => {
      cancelTokenGetPlaces();
    };
  };

  const overallStateSearch = useCallback(
    debounce((state) => overallStateSearchDebounce(state), 1000),
    []
  );
  const availableLocationSearch = useCallback(
    debounce((locations) => availableLocationSearchDebounce(locations), 1000),
    []
  );

  useEffect(() => {
    setTimeout(() => {
      setAnimationState(true);
    }, 100);

    setVehicleTypesLoading(true);
    getVehicletypes()
      .then((res) => setVehicleTypes(res.data))
      .finally(setVehicleTypesLoading(false));

    setEventTypesLoading(true);
    getEventSeverityList()
      .then((res) =>
        setEventTypes(
          Object.entries(res.data).map(([key, val]) => ({ val, title: key }))
        )
      )
      .finally(setEventTypesLoading(false));
  }, []);

  // LOAD NEW EVENTS AND VEHICLES
  useEffect(() => {
    overallStateSearch(overallState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overallState]);

  useEffect(() => {
    if (eventPage !== 1 && totalEventsCount > eventsList.length) {
      if (eventsLoading) {
        cancelTokenSearchEvents();
      }
      setEventsLoading(true);
      getSearchEvents(overallState, eventPage, 10)
        .then((res) => {
          const { items: events } = res.data;
          setEventsList((prev) => [...prev, ...events]);
        })
        .finally((_) => setEventsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventPage]);

  useEffect(() => {
    if (vehiclePage !== 1 && totalVehicleCount > vehicleList.length) {
      if (vehiclesLoading) {
        cancelTokenSearchVehicles();
      }
      setVehiclesLoading(true);
      getSearchVehicles(overallState, vehiclePage, 10)
        .then((res) => {
          const { items: vehicles } = res.data;
          setVehicleList((prev) => [...prev, ...vehicles]);
        })
        .finally((_) => setVehiclesLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclePage]);

  const handleScrollEvents = () => {
    const totalHeight = eventsBlockContainer.current.scrollHeight;
    const shownHeight = eventsBlockContainer.current.offsetHeight;
    const heightFromTop = eventsBlockContainer.current.scrollTop;
    if (totalHeight - (shownHeight + heightFromTop) < 100 && !eventsLoading) {
      setEventPage((prev) => prev + 1);
    }
  };

  const handleScrollVehicles = () => {
    const totalHeight = vehiclesBlockContainer.current.scrollHeight;
    const shownHeight = vehiclesBlockContainer.current.offsetHeight;
    const heightFromTop = vehiclesBlockContainer.current.scrollTop;
    if (totalHeight - (shownHeight + heightFromTop) < 100 && !vehiclesLoading) {
      setVehiclePage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    availableLocationSearch(availableLocationSearchQuery);
  }, [availableLocationSearchQuery]);

  useEffect(() => {
    if (location.search) {
      setOverallState((prev) => ({
        ...queryString.parse(location.search),
        ...(queryString.parse(location.search).dateRange && {
          dateRange: queryString
            .parse(location.search)
            .dateRange.map((item) => new Date(item)),
        }),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
      className={animationState ? "search-container show" : "search-container"}
    >
      {/* TOP SEARCH PART */}
      <div
        style={{
          height: "17%",
          backgroundColor: "#43A0EE",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Input.Search
          value={overallState.globalSearch}
          onChange={(e) => {
            e.persist();
            setOverallState((prev) => ({
              ...prev,
              globalSearch: e.target.value,
            }));
          }}
          placeholder="Suche..."
          size="large"
          className="w-50"
        />
        {/* CLOSE WITH ABSOLUTE POSITIONING */}
        <CloseOutlined
          onClick={() => history.push("/dashboard")}
          style={{
            color: "white",
            fontSize: "20px",
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        />
      </div>

      {/* FILTERING SECTION */}
      <div className="p-4 row">
        <div className="col-md-3 col-sm-12">
          <p>Zeitraum</p>
          <DatePicker.RangePicker
            value={
              overallState.dateRange &&
              overallState.dateRange.map((item) => moment(item).utc())
            }
            onChange={(val) =>
              val
                ? setOverallState((prev) => ({
                    ...prev,
                    dateRange: [val[0].toDate(), val[1].toDate()],
                  }))
                : setOverallState((prev) => ({ ...prev, dateRange: null }))
            }
          />
          <Divider />
          <p>Fahrzeug-Typ</p>
          <Select
            loading={vehicleTypesLoading}
            value={overallState.vehicleType}
            style={{ width: "100%" }}
            onChange={(carType) =>
              setOverallState((prev) => ({ ...prev, vehicleType: carType }))
            }
          >
            <Select.Option value="alle">Alle</Select.Option>
            {vehicleTypes.map((carItem) => (
              <Select.Option key={carItem} value={carItem}>
                {carItem}
              </Select.Option>
            ))}
          </Select>
          <Divider />
          <p>Ort</p>
          <Select
            allowClear={true}
            loading={availableLocationsLoading}
            showSearch
            placeholder="Ort auswählen"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            style={{ width: "100%" }}
            onChange={(text) =>
              text
                ? setOverallState((prev) => ({
                    ...prev,
                    place: JSON.parse(text),
                  }))
                : setOverallState((prev) => ({ ...prev, place: null }))
            }
            onSearch={(text) => setAvailableLocationSearchQuery(text)}
            notFoundContent={null}
          >
            {availableLocations.map((item) => (
              <Select.Option
                key={item.formatted_address}
                value={JSON.stringify(item.geometry.location)}
              >
                {item.formatted_address}
              </Select.Option>
            ))}
          </Select>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mt-3">Radius</p>
            <p className="mt-3">{overallState.radius}KM</p>
          </div>
          <Slider
            value={overallState.radius}
            onChange={(radius) =>
              setOverallState((prev) => ({ ...prev, radius }))
            }
            tipFormatter={formatter}
          />
          <Divider />
          <p>Ereignis-Typ</p>
          <Select
            loading={eventTypesLoading}
            value={overallState.eventType}
            onChange={(eventType) =>
              setOverallState((prev) => ({ ...prev, eventType }))
            }
            style={{ width: "100%" }}
          >
            <Select.Option value="alle">Alle</Select.Option>
            {eventTypes.map((item) => (
              <Select.Option key={item.val} value={item.title}>
                {item.val}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 col-sm-12 ml-4 pl-4">
          <div className="d-flex justify-content-between">
            <h4>Ereignisse</h4>
            <Button
              type="dashed"
              onClick={(_) => setEventsMapState((prev) => !prev)}
              shape="round"
              icon={
                eventsMapState ? <CompassOutlined /> : <UnorderedListOutlined />
              }
              size={20}
            />
          </div>
          {!eventsMapState ? (
            <div
              ref={eventsBlockContainer}
              onScroll={handleScrollEvents}
              style={{ height: "70vh", overflow: "scroll" }}
            >
              {eventsList.map((item, index) => (
                <Card
                  onClick={() => history.push(`/ereignisse/${item.eventId}`)}
                  style={{ cursor: "pointer" }}
                  key={item.eventId}
                  bodyStyle={{ padding: "5px" }}
                  className="shadow-sm mt-3 search-event-card"
                >
                  <span
                    style={{ color: "rgb(115, 115, 115)" }}
                    className="d-block"
                  >
                    <span style={{ fontFamily: "TKFont Medium" }}>Schaden</span>{" "}
                    {item.eventId}
                  </span>
                  <p>{item.severity}</p>
                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{ color: "rgb(115, 115, 115)" }} className="mb-0">
                      <span style={{ fontFamily: "TKFont Medium" }}>
                        Fahrzeug
                      </span>{" "}
                      {item.vehicleId}
                    </p>
                    <p style={{ color: "#989898" }} className="mb-0">
                      {item.date && (
                        <span className="pr-2">
                          {`${da.format(new Date(item.date))}.${mo.format(
                            new Date(item.date)
                          )}.${ye.format(new Date(item.date))}`}
                        </span>
                      )}
                    </p>
                  </div>
                </Card>
              ))}
              {eventsLoading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "60px" }}
                >
                  <LoadingOutlined
                    style={{ fontSize: 45, color: "#6EB8F4" }}
                    spin
                  />
                </div>
              )}
              {totalEventsCount <= eventsList.length && (
                <p className="mt-3 text-center">
                  Keine weiteren Daten vorhanden.
                </p>
              )}
            </div>
          ) : (
            <div style={{ height: "70vh", width: "100%" }}>
              <SearchMap
                mapState="events"
                data={eventsList}
                loading={eventsLoading}
              />
            </div>
          )}
        </div>
        <div className="col-md-3 col-sm-12 ml-4 pl-4">
          <div className="d-flex justify-content-between">
            <h4>Fahrzeuge</h4>
            <Button
              type="dashed"
              onClick={(_) => setVehicleMapState((prev) => !prev)}
              shape="round"
              icon={
                vehicleMapState ? (
                  <CompassOutlined />
                ) : (
                  <UnorderedListOutlined />
                )
              }
              size={20}
            />
          </div>

          {!vehicleMapState ? (
            <div
              ref={vehiclesBlockContainer}
              onScroll={handleScrollVehicles}
              style={{ height: "70vh", overflow: "scroll" }}
            >
              {vehicleList.map((item, index) => (
                <Card
                  onClick={() => history.push(`/fahrzeug/${item.vehicleId}`)}
                  style={{ cursor: "pointer" }}
                  bodyStyle={{ padding: "5px" }}
                  className="shadow-sm mt-3 search-vehicle-card"
                  key={item.vehicleId}
                >
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <span
                        style={{ color: "rgb(115, 115, 115)" }}
                        className="d-block"
                      >
                        <span style={{ fontFamily: "TKFont Medium" }}>
                          Fahrzeug
                        </span>{" "}
                        {item.vehicleId}
                      </span>
                      <span>{item.stationName}</span>
                    </div>
                    <span
                      className="mr-2"
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "15px",
                        backgroundColor: convertSeverityToColor(item.severity),
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginTop: "1px" }}>
                        {item.eventCount}
                      </span>
                    </span>
                  </div>

                  {item.fwUpdateDatetime ? (
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "rgb(115, 115, 115)" }}>
                        {`${da.format(
                          new Date(item.fwUpdateDatetime)
                        )}.${mo.format(
                          new Date(item.fwUpdateDatetime)
                        )}.${ye.format(new Date(item.fwUpdateDatetime))}`}
                      </span>
                    </div>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#969696",
                      }}
                    >
                      Keine Update Date gefunden{" "}
                      <InfoCircleOutlined
                        style={{
                          color: "#FFB400",
                          marginLeft: "4px",
                          marginBottom: "3px",
                        }}
                      />
                    </span>
                  )}
                </Card>
              ))}
              {vehiclesLoading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "60px" }}
                >
                  <LoadingOutlined
                    style={{ fontSize: 45, color: "#6EB8F4" }}
                    spin
                  />
                </div>
              )}
              {totalVehicleCount <= vehicleList.length && (
                <p className="mt-3 text-center">
                  Keine weiteren Daten vorhanden.
                </p>
              )}
            </div>
          ) : (
            <div style={{ height: "70vh", width: "100%" }}>
              <SearchMap
                mapState="vehicles"
                data={vehicleList}
                loading={vehiclesLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchScreen;
