import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import authentication from "@kdpw/msal-b2c-react";
import "./assets/css/fontawesome.min.css";
import { ConfigProvider } from "antd";
import deDE from "antd/es/locale/de_DE";

authentication.initialize({
  // you can user your b2clogin.com domain here, setting is optional, will default to this
  instance: "https://carvalooTenant.b2clogin.com/tfp/",
  // your B2C tenant, you can also user tenants GUID here
  tenant: "carvalooTenant.onmicrosoft.com",
  // the policy to use to sign in, can also be a sign up or sign in policy
  signInPolicy: process.env.REACT_APP_SIGN_IN_POLICY,
  // the policy to use for password reset
  resetPolicy: process.env.REACT_APP_RESET_POLICY,
  // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
  applicationId: process.env.REACT_APP_APPLICATION_ID,
  // where MSAL will store state - localStorage or sessionStorage
  cacheLocation: "localStorage",
  // the scopes you want included in the access token
  scopes: [process.env.REACT_APP_SCOPES],
  // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  // // optional, the URI to redirect to after logout
  postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL,
  // optional, default to true, set to false if you change instance
  validateAuthority: false,
  // optional, default to false, set to true if you only want to acquire token silently and avoid redirections to login page
  silentLoginOnly: false,
});

authentication.run((a) => {
  const preventConsoleLog = () => {
    if (process.env.NODE_ENV === "production") {
      console.log = function () {};
    }
  };

  preventConsoleLog();

  ReactDOM.render(
    <ConfigProvider locale={deDE}>
      <App />
    </ConfigProvider>,
    document.getElementById("root")
  );
});
// ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
