import React, { useState, useEffect, useRef } from "react";
import InfoCardGenericType from "../ClusterMap/InfoCardGenericType/InfoCardGenericType";
import InfoCardVehicle from "../ClusterMap/InfoCardVehicle/InfoCardVehicle";
import GoogleMapReact from "google-map-react";
import Supercluster from "supercluster";
import { customGoogleMapsStyle } from "../../assets/styles/google-maps-style";
import MapPin from "../ClusterMap/MapPin/MapPin";

const Marker = ({ children }) => children;
let clusterObj;
let mapsItem;

const SearchMap = (props) => {
  const mapRef = useRef();
  const [mapLocations, setMapLocations] = useState([]);
  const [mapSuperCluster, setMapSuperCluster] = useState([]);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lat: 46.9797477722168,
    lng: 7.468743991851807,
  });
  const [bounds, setBounds] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  useEffect(() => {
    if (props.data) {
      if (props.mapState === "events") {
        const points = props.data
          .map(({ lat, lng, ...rest }) => {
            if (lat && lng) {
              return {
                type: "Feature",
                properties: {
                  id: rest.eventId,
                  ...rest,
                },
                geometry: {
                  type: "Point",
                  coordinates: [parseFloat(lng), parseFloat(lat)],
                },
              };
            }
          })
          .filter((item) => item);
        setMapLocations(points);
      }
      if (props.mapState === "vehicles") {
        const points = props.data
          .map(({ stationLatitude, stationLongitude, ...rest }) => {
            if (stationLatitude && stationLongitude) {
              return {
                type: "Feature",
                properties: {
                  id: rest.vehicleId,
                  ...rest,
                },
                geometry: {
                  type: "Point",
                  coordinates: [
                    parseFloat(stationLongitude),
                    parseFloat(stationLatitude),
                  ],
                },
              };
            }
          })
          .filter((item) => item);
        setMapLocations(points);
      }
    }
  }, [props]);

  useEffect(() => {
    if (mapLocations.length) {
      const index = new Supercluster({
        radius: 75,
        maxZoom: 20,
        zoom,
        bounds,
      });
      index.load(mapLocations);
      setMapSuperCluster(index.getClusters([-180, -85, 180, 85], zoom));
      clusterObj = index;
    }
  }, [mapLocations, zoom, bounds]);

  const handleMapLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsItem = maps;
  };

  return (
    <div
      className="shadow"
      style={{ height: "100%", width: "100%", position: "relative" }}
    >
      <GoogleMapReact
        options={{
          zoomControl: false,
          fullscreenControl: false,
          styles: customGoogleMapsStyle,
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleMapLoaded}
        bootstrapURLKeys={{
          key: "AIzaSyBWE9hyofUVJVT4_77kuW3Y4R25r4-i4nU",
        }}
        onDrag={() => setSelectedMarker(null)}
        center={center}
        defaultZoom={8}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {mapSuperCluster.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties;
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker shadow d-flex justify-content-center align-items-center"
                  style={{
                    color: "white",
                    backgroundColor: "#F4CA7C",
                    opacity: 0.8,
                    padding: 10 + (pointCount / mapLocations.length) * 40,
                    borderRadius: 100,
                    width: 10 + (pointCount / mapLocations.length) * 20,
                    height: 10 + (pointCount / mapLocations.length) * 20,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      clusterObj.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({
                      lat: latitude,
                      lng: longitude,
                    });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          return (
            <Marker
              key={`crime-${cluster.properties.id}`}
              lat={latitude}
              lng={longitude}
            >
              <div style={{ position: "relative" }}>
                <div
                  className="shadow"
                  style={{
                    position: "absolute",
                    height:
                      props.mapState === "events"
                        ? "230px"
                        : props.mapState === "vehicles" && "180px",
                    width: "250px",
                    backgroundColor: "white",
                    zIndex: 100,
                    transform:
                      props.mapState === "vehicles"
                        ? "translate(-110px, -185px)"
                        : "translate(-110px, -230px)",
                    borderRadius: "5px",
                    transition: "all 0.5s",
                    opacity: selectedMarker === cluster.properties.id ? 1 : 0,
                    maxHeight:
                      selectedMarker === cluster.properties.id ? 300 : 0,
                    overflow: "hidden",
                  }}
                >
                  {props.mapState === "events" ? (
                    <InfoCardGenericType eventId={cluster.properties.eventId} />
                  ) : (
                    props.mapState === "vehicles" && (
                      <InfoCardGenericType vehicleId={cluster.properties.vehicleId} />
                    )
                  )}
                </div>
                <button
                  onClick={() => {
                    setCenter({
                      lat: latitude,
                      lng: longitude,
                    });
                    setTimeout(() => {
                      mapRef.current.panBy(0, -100);
                    }, 100);
                    setSelectedMarker(cluster.properties.id);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  className="crime-marker"
                >
                  <MapPin
                    eventStatus={props.mapState === "events"}
                    size={{ height: 25, width: 25 }}
                    severity={cluster.properties && cluster.properties.severity}
                    eventCount={
                      cluster.properties && cluster.properties.eventCount
                    }
                  />
                </button>
              </div>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default SearchMap;
