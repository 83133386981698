import axios from "axios";
import { axiosInstance } from "./axiosInstance";
import authentication from "@kdpw/msal-b2c-react";

export let cancelTokenGetPlaces;
export let cancelTokenSearchEvents;
export let cancelTokenSearchVehicles;

export const getPlaces = (queryString) => {
  return axiosInstance.get(`api/Search/GoogleLocation/?query=${queryString}`, {
    headers: {
      Authorization: `Bearer ${authentication.getAccessToken()}`,
    },
    cancelToken: new axios.CancelToken(function (c) {
      cancelTokenGetPlaces = c;
    }),
  });
};

export const getSearchEvents = (filter, pageIndex) => {
  return axiosInstance.post(
    `api/Search/Event`,
    {
      filter,
      pageIndex,
      pageSize: 10,
      sorting: 0,
    },
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        cancelTokenSearchEvents = c;
      }),
    }
  );
};

export const getSearchVehicles = (filter, pageIndex) => {
  return axiosInstance.post(
    `api/Search/Vehicle`,
    {
      filter,
      pageIndex,
      pageSize: 10,
      sorting: 0,
    },
    {
      headers: {
        Authorization: `Bearer ${authentication.getAccessToken()}`,
      },
      cancelToken: new axios.CancelToken(function (c) {
        cancelTokenSearchVehicles = c;
      }),
    }
  );
};
