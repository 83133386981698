import React, { useState, useEffect } from "react";

import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { useHistory } from "react-router-dom";
import CarValooLogo from "../../assets/images/carvaloo-logo.png";
import styles from "./DocumentScreen.module.scss";

function DocumentScreen({ children }) {
  const [animationState, setAnimationState] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setAnimationState(true);
    }, 100);
  }, []);
  return (
    <>
      <div
        className={
          animationState ? "search-container show" : "search-container"
        }
      >
        <div
          className={`${styles.header} row pb-3 pl-5 pr-3 pt-2`}
          style={{ height: "auto", backgroundColor: "rgb(0, 160, 245)" }}
        >
          <div className="col-10">
            <div className="logo">
              <img
                className="mt-3"
                src={CarValooLogo}
                style={{ width: "50px" }}
                alt=""
              />
            </div>
          </div>
          <div className="col-2">
            <CloseOutlined
              onClick={() => history.goBack()}
              style={{
                color: "white",
                fontSize: "20px",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="container py-5">{children}</div>
      </div>
    </>
  );
}

export default DocumentScreen;
