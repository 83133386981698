export const convertSeverityToColor = (severity, isLogger = false) => {
  severity = severity === null ? "NaN" : severity;

  if(typeof severity == "string") {
    if(severity.startsWith("#")) {
      return severity;
    }
  }
  if (severity <= 1) return "#0F968C";
  if (severity == 2) return "#FFB400";
  if (severity >= 3) return "#D42E53";
  return "#969696";
};