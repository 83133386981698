import {
  GET_MONITORING_START,
  GET_MONITORING_SUCCESS,
  EMPTY_MONITORING,
  SET_TOTAL_MONITORING,
  LOADING_DONE,
  EMPTY_MONITORING_AND_FILL,
  INCREMENT_SINGLE_MONITORING_COUNT,
} from "../types/monitoring-types";

const initialState = {
  monitoringLoading: false,
  totalMonitoringCount: null,
  monitoring: [],
};

const MonitoringsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MONITORING_START:
      return {
        ...state,
        monitoringLoading: true,
        monitoring: [],
      };
    case GET_MONITORING_SUCCESS:
      return {
        ...state,
        monitoring: [...state.monitoring, ...action.payload],
        monitoringLoading: false,
      };
    case EMPTY_MONITORING_AND_FILL:
      return {
        ...state,
        monitoring: action.payload,
        monitoringLoading: false,
      };
    case EMPTY_MONITORING:
      return { ...state, monitoring: [] };
    case SET_TOTAL_MONITORING:
      return { ...state, totalMonitoringCount: action.payload };
    case INCREMENT_SINGLE_MONITORING_COUNT:
      const vehicleIndex = state.monitoring.findIndex(
        (item) => item.vehicleId === +action.payload
      );
      if (vehicleIndex !== -1) {
        return {
          ...state,
          monitoring: [
            ...state.monitoring.slice(0, vehicleIndex),
            {
              ...state.monitoring[vehicleIndex],
              eventCount: state.monitoring[vehicleIndex].eventCount + 1,
            },
            ...state.monitoring.slice(vehicleIndex + 1),
          ],
        };
      } else {
        return state;
      }
    case LOADING_DONE:
      return { ...state, monitoringLoading: false };
    default:
      return state;
  }
};

export default MonitoringsReducer;
