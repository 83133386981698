import {
  GET_ROLES_START,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILED,
  UPDATE_ORGANIZATION,
} from "../types/user-types";

const initialState = {
  rolesLoading: false,
  roleData: {
    id: null,
    email: null,
    gender: null,
    userName: null,
    lastName: null,
    roleName: null,
    organizations: [],
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES_START:
      return {
        ...state,
        rolesLoading: true,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roleData: { ...action.payload },
        rolesLoading: false,
      };
    case GET_ROLES_FAILED:
      return {
        ...state,
        rolesLoading: false,
      };
    case UPDATE_ORGANIZATION:
      const roleData = { ...state.roleData, organizationName: action.payload };
      state.roleData.organizationName = action.payload;
      return {
        ...state,
        roleData,
      };

    default:
      return state;
  }
};

export default UserReducer;
