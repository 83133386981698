import React from "react";

// Screens
import DocumentScreen from "../../screens/DocumentScreen/DocumentScreen";

// Language Services
import { useLanguageContext } from "../../language-service/container/Language";

const ImpressumScreen = () => {
  const { dictionary } = useLanguageContext();
  return (
    <DocumentScreen>
      <div className="row">
        <div className="col-12">
          <h2 className="display-3">{dictionary.impressumScreen_title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h4>carValoo GmbH</h4>
        </div>
        <div className="col-12">
          <p>
            A group company of thyssenkrupp Automotive Technology
            <br />
            ThyssenKrupp Allee 1
            <br />
            45143 Essen
            <br />
            {dictionary.impressumScreen_countryName}
          </p>
        </div>
        <div className="col-12">
          <p>
            {dictionary.impressumScreen_phone}: +49 201 844-0
            <br />
            {dictionary.impressumScreen_fax}: +49 201 844-536000
            <br />
            {dictionary.impressumScreen_mail}: info@carValoo.com
          </p>
        </div>
        <div className="col-12">
          <h4>{dictionary.impressumScreen_executiveBoard}: </h4>
        </div>
        <div className="col-12">
          <p>
            Dr. Tom Althoff
            <br />
            Nico Schön
            <br />
            Dr. Sophie Wei
          </p>
        </div>
        <div className="col-12">
          <h4>{dictionary.impressumScreen_commercialRegister}: </h4>
        </div>
        <div className="col-12">
          <p>Essen HRB 29642</p>
        </div>
        <div className="col-12">
          <h4>{dictionary.impressumScreen_registeredOffice}: </h4>
        </div>
        <div className="col-12">
          <p>
            Essen, {dictionary.impressumScreen_countryName}
            <br />
            {dictionary.impressumScreen_VAT_numberText}: DE 325 163 148
            <br />
            {dictionary.impressumScreen_tax_numberText && (
              <span>
                {dictionary.impressumScreen_tax_numberText}: 112/5795/0833
              </span>
            )}
          </p>
        </div>
      </div>
    </DocumentScreen>
  );
};

export default ImpressumScreen;
