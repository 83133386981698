import {
  GET_VEHICLES_START,
  GET_VEHICLES_SUCCESS,
  EMPTY_VEHICLES,
  SET_TOTAL_VEHICLES,
  LOADING_DONE,
  EMPTY_VEHICLES_AND_FILL,
  INCREMENT_SINGLE_VEHICLE_COUNT,
  SET_VEHICLE_UVID,
} from "../types/vehicle-types";

const initialState = {
  vehiclesLoading: false,
  totalVehicleCount: null,
  UVID: null,
  vehicles: [],
};

const VehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLES_START:
      return {
        ...state,
        vehiclesLoading: true,
      };
    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: [...state.vehicles, ...action.payload],
        vehiclesLoading: false,
      };
    case EMPTY_VEHICLES_AND_FILL:
      return {
        ...state,
        vehicles: action.payload,
        vehiclesLoading: false,
      };
    case EMPTY_VEHICLES:
      return { ...state, vehicles: [] };
    case SET_TOTAL_VEHICLES:
      return { ...state, totalVehicleCount: action.payload };
    case INCREMENT_SINGLE_VEHICLE_COUNT:
      const vehicleIndex = state.vehicles.findIndex(
        (item) => item.vehicleId === +action.payload
      );
      if (vehicleIndex !== -1) {
        return {
          ...state,
          vehicles: [
            ...state.vehicles.slice(0, vehicleIndex),
            {
              ...state.vehicles[vehicleIndex],
              eventCount: state.vehicles[vehicleIndex].eventCount + 1,
            },
            ...state.vehicles.slice(vehicleIndex + 1),
          ],
        };
      } else {
        return state;
      }
    case LOADING_DONE:
      return { ...state, vehiclesLoading: false };
    case SET_VEHICLE_UVID:
      return { ...state, UVID: action.payload };
    default:
      return state;
  }
};

export default VehiclesReducer;
