import axios from "axios";

const ORGANIZATION_NAME = "organizationName";

function getUserName() {
  const organizationName = localStorage.getItem(ORGANIZATION_NAME);
  return organizationName;
}

const getScreen = () => {
  const pathname = window.location.pathname;
  if(pathname.includes('ereignisse')) return 'event';
  if(pathname.includes('fahrzeug')) return 'vehicle';
  if(pathname.includes('dashboard')) return 'dashboard';
  if(pathname.includes('search')) return 'search';
  if(pathname.includes('fleet')) return 'fleet';
  if(pathname.includes('statistic')) return 'stats';
}

let axiosInstanceLocal = axios.create({
  baseURL: 'http://localhost:7071'//process.env.REACT_APP_API_ENDPOINT,
});

axiosInstanceLocal.interceptors.request.use(function (config) {
  if (config.headers.SkipOrganizationName) {
    delete config.headers.SkipOrganizationName;
  } else {
    config.headers.OrganizationId = getUserName();
  }

  config.headers.language = !localStorage.getItem('rcml-lang') ? 'DE' : localStorage.getItem('rcml-lang').toUpperCase();
  config.headers.screen = getScreen();

  return config;
});

export { axiosInstanceLocal };
