import de from "./de.json";
import en from "./en.json";
import es from "./es.json";

// Antd Locale
import deDe from "antd/lib/locale/de_DE";
import enUs from "antd/lib/locale/en_US";
import esEs from "antd/lib/locale/es_ES";
// Flags
import germany from "../../assets/images/germany.svg";
import unitedKingdom from "../../assets/images/united-kingdom.svg";
import spain from "../../assets/images/spain-flag.svg";

export const dictionaryList = {
  de: {
    dictionary: de,
    antdLocale: deDe,
  },
  en: {
    dictionary: en,
    antdLocale: enUs,
  },
  es: {
    dictionary: es,
    antdLocale: esEs,
  },
};

export const languageOptions = {
  en: {
    name: "en",
    flag: unitedKingdom,
  },
  de: {
    name: "de",
    flag: germany,
  },
  es: {
    name: "es",
    flag: spain,
  },
};
