import React from "react";
import { NavLink } from "react-router-dom";
import  LanguageSelector from "../LanguageSelector/LanguageSelector";
import MailOutlined from "@ant-design/icons/MailOutlined";

// Language Service
import { useLanguageContext } from "../../language-service/container/Language";

const Footer = () => {
  const {dictionary} = useLanguageContext()
  return (
    <div
      className="shadow-lg"
      style={{
        height: "50px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 20,
        width: '100%',
      }}
    >
      <div style={{ display: "flex" }}>
        <p className="mb-0 pl-4">
          carValoo GmbH{" "}
          <span role="img" aria-label="">
            ©️
          </span>{" "}
          {new Date().getFullYear()}
        </p>
      </div>
      <img
        src={require("../../assets/images/red-dot-logo.jpg")}
        className="pl-5"
        alt=""
        style={{ height: "40px" }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <a
          href="mailto:webapp-support@carvaloo.com"
          className="d-flex flex-column align-items-center"
          style={{paddingTop: 2}}
        >
          <MailOutlined  />
        </a>
        <p className="mb-0 px-2"> | </p>
        <NavLink
          activeClassName="menu-active"
          to="/nutzungsbedingungen"
          className="d-flex flex-column align-items-center"
        >
          <p
            style={{
              color: "#44A0E9",
              textDecoration: "underline",
              textUnderlineOffset: "5px",
            }}
            className="mb-0"
          >
            {dictionary.footer__terms_of_use_text}
          </p>
        </NavLink>
        <p className="mb-0 px-2"> | </p>
        <NavLink
          activeClassName="menu-active"
          to="/datenschutzerklärung"
          className="d-flex flex-column align-items-center"
        >
          <p
            style={{
              color: "#44A0E9",
              textDecoration: "underline",
              textUnderlineOffset: "5px",
            }}
            className="mb-0"
          >
            {dictionary.footer__security_text}
          </p>
        </NavLink>
        <p className="mb-0 px-2"> | </p>
        <NavLink
          activeClassName="menu-active"
          to="/impressum"
          className="d-flex flex-column align-items-center"
        >
          <p
            style={{
              color: "#44A0E9",
              textDecoration: "underline",
              textUnderlineOffset: "5px",
            }}
            className="mb-0"
          >
            {dictionary.footer__imprint_text}
          </p>
        </NavLink>
        <LanguageSelector />

      </div>
    </div>
  );
};

export default Footer;
