import {
  SET_ALL,
  INIT_ORGANIZATION_SCREEN_PERMISSIONS2,
} from "../types/organization-types";

export const setOrganizationConfig = ({ guide, config = {} }) => ({
  type: SET_ALL,
  payload: {
    guide: guide,
    config: config,
  },
});

export const initializeOrganizationScreenPermissions2 = (permissions) => ({
  type: INIT_ORGANIZATION_SCREEN_PERMISSIONS2,
  payload: permissions,
});