import React from "react";
import PropTypes from "prop-types";
import { Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  faXmark,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
import Markdown from "../Markdown/Markdown";
const IconSeverity = (props) => {
  const renderIcon = (icon, iconColor) => {
    switch (icon) {
      case "faXmark":
        return (
          <FontAwesomeIcon
            icon={faXmark}
            size="lg"
            color={iconColor ? iconColor : "#56b8f9"}
          />
        );
      case "faScrewdriverWrench":
        return (
          <FontAwesomeIcon
            icon={faScrewdriverWrench}
            size="lg"
            color={iconColor ? iconColor : "#56b8f9"}
          />
        );
      case "faCheck":
        return (
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color={iconColor ? iconColor : "#56b8f9"}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            icon={faCheck}
            size="lg"
            color={iconColor ? iconColor : "#56b8f9"}
          />
        );
    }
  };
  let title, content, icon, iconColor;
  icon = props.textBox?.titleIcon || null;
  iconColor = props.textBox?.titleIconColor || null;
  title =
    (
      <span style={{ display: "flex", justifyContent: "space-between" }}>
        <strong>
          <p style={{ margin: 0 }}>{props.textBox?.title} </p>
        </strong>
        {renderIcon(icon, iconColor)}
      </span>
    ) || "";
  content =
    props.textBox?.info_text?.map((item, index) => {
      return (
        <div>
          <Markdown>{item.label}</Markdown>
          <Markdown>{item.value}</Markdown>
        </div>
      );
    }) || "";
  return (
    <>
      {props.textBox != null ? (
        <Popover
          content={
            <div
              className={
                props.textBox.info_text.length > 2 ? "custom-scrollbar" : ""
              }
              style={{
                maxHeight: 150,
                overflowY: "scroll",
                padding: 2.5,
              }}
            >
              {content}
            </div>
          }
          title={title}
          trigger="hover"
          mouseEnterDelay={0.1}
          mouseLeaveDelay={0.5}
        >
          <svg
            viewBox="0 0 65.333 64"
            x={props.positionX}
            y={props.positionY}
            height={props.height}
            width={props.width}
          >
            <path
              d="M0 32C0 14.327 14.625 0 32.667 0c18.041 0 32.666 14.327 32.666 32 0 17.673-14.625 32-32.666 32C14.625 64 0 49.673 0 32z"
              fill={props.outSideColor}
              fillOpacity={0.149}
            />
            <path
              d="M21.333 31.333C21.333 25.075 26.408 20 32.667 20 38.925 20 44 25.075 44 31.333c0 6.259-5.075 11.334-11.333 11.334-6.259 0-11.334-5.075-11.334-11.334z"
              fill={props.inSideColor}
            />
          </svg>
        </Popover>
      ) : (
        <svg
          viewBox="0 0 65.333 64"
          x={props.positionX}
          y={props.positionY}
          height={props.height}
          width={props.width}
        >
          <path
            d="M0 32C0 14.327 14.625 0 32.667 0c18.041 0 32.666 14.327 32.666 32 0 17.673-14.625 32-32.666 32C14.625 64 0 49.673 0 32z"
            fill={props.outSideColor}
            fillOpacity={0.149}
          />
          <path
            d="M21.333 31.333C21.333 25.075 26.408 20 32.667 20 38.925 20 44 25.075 44 31.333c0 6.259-5.075 11.334-11.333 11.334-6.259 0-11.334-5.075-11.334-11.334z"
            fill={props.inSideColor}
          />
        </svg>
      )}
    </>
  );
};

IconSeverity.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  outSideColor: PropTypes.string,
  inSideColor: PropTypes.string,
};
IconSeverity.defaultProps = {
  width: 64,
  height: 64,
  outSideColor: "#7f7f7f",
  insideColor: "#7f7f7f",
};
export default IconSeverity;
