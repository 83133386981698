import {
  SET_ALL,
  INIT_ORGANIZATION_SCREEN_PERMISSIONS,
  INIT_ORGANIZATION_SCREEN_PERMISSIONS2,
} from "../types/organization-types";

const initialState = {
  guide: {},
  config: {},
  screenPermissions2: {},
};

const OrganizationConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL:
      return {
        ...state,
        guide: action.payload.guide,
        config: action.payload.config,
      };
    case INIT_ORGANIZATION_SCREEN_PERMISSIONS2:
      return {
        ...state,
        screenPermissions2: {
          ...action.payload
        }
      }
    default:
      return state;
  }
};

export default OrganizationConfigReducer;
