import React from 'react'
import { Badge } from 'antd'
import { NavLink } from 'react-router-dom'

const SideBarItem = ({content, to, imgSrc, eventBadgeData}) => {
  const isEventItem = to === '/ereignisse';
  const img = (
    <img
      src={imgSrc}
      style={{ width: "45px" }}
      className="pt-1"
      alt=""
    />
  )
  return (
    <NavLink
      activeClassName="menu-active"
      to={to}
      className="d-flex flex-column align-items-center menu-item"
    >
      {isEventItem ? (
        <Badge overflowCount={99} style={{backgroundColor: '#faad14', top: 8, right: 4}} count={eventBadgeData.data}>
          {img}
        </Badge>) : img
      }
      <span
        className="pb-1"
        style={{ color: "#ffffff", fontSize: "12px" }}
      >
        {content}
      </span>
    </NavLink>
  )
}

export default SideBarItem
